import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Network, {NetworkMethod} from '../network/Network';
import {User} from '../store/types/User';
import Storage from '../store/Storage';
import {setCurrentUser} from '../store/reducers/UserReducer';
import {setError500, setIsLoading} from '../store/reducers/AppReducer';
import {PendingUser} from '../store/types/PendingUser';
import SelectOption from '../store/types/SelectionOption';
import Office from '../store/types/Office';
import City from '../store/types/City';


class UserService {

  // LOGIN
  static useLogin = (): [User, any, (email: string, password: string) => void] => { // Chained requests
    const [error, setError] = useState();
    const [token, loginError, loginRequest] = UserService.useLoginAPI();
    const [user, userError, userRequest] = UserService.useUserAPI();

    const request = async (email: string, password: string) => {
      loginRequest(email, password);
    };

    useEffect(() => {
      if (token) {
        userRequest();
      }

    }, [token]);

    useEffect(() => {
      setError(loginError);

    }, [loginError]);


    useEffect(() => {
      setError(userError);

    }, [userError]);

    return [user, error, request];
  };

  static useRegister = (): [User, any, (pendingSignUpUser: PendingUser) => void] => { // Chained requests
    const [error, setError] = useState();
    const [token, registerError, registerRequest] = UserService.useRegisterAPI();
    const [user, userError, userRequest] = UserService.useUserAPI();

    const request = async (pendingSignUpUser: PendingUser) => {
      registerRequest(pendingSignUpUser);
    };

    useEffect(() => {
      if (token) {
        userRequest();
      }

    }, [token]);

    useEffect(() => {
      setError(registerError);

    }, [registerError]);


    useEffect(() => {
      setError(userError);

    }, [userError]);

    return [user, error, request];
  };

  // REGISTER
  static useUserAPI = (): [User, any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async () => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.get;
      const endpoint = 'users/me/';

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        dispatch(setCurrentUser(networkResponse.data));
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

  static useUpdateUserAPI = (): [User, any, (user: User) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (user: User) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.put;
      const endpoint = 'auth/user/';
      const data = JSON.stringify(user);

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        dispatch(setCurrentUser(networkResponse.data));
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };

  // USER VIA AUTH
  static useResetPasswordAPI = (): [boolean, any, (email: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (email: string) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'auth/password/reset/';
      const data = JSON.stringify({email});

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };

  static useChangePasswordAPI = (): [boolean, any, (password: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (password: string) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'auth/password/change/';
      const data = JSON.stringify({
        new_password1: password,
        new_password2: password
      });

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };

  static useSearchSectionsAPI = (): [SelectOption[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = 'aigasections/';
      const params = {
        search: query
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const sections = (networkResponse.data as any[]).map((section: Office) => new SelectOption(section, section.name));
        setResponse(sections);
      }
    };

    return [response, error, request];
  };

  static useSearcCitiesAPI = (): [SelectOption[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = 'cities/';
      const params = {
        search: query
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const cities = (networkResponse.data as any[]).map((city: City) => new SelectOption(city, city.name));
        setResponse(cities);
      }
    };

    return [response, error, request];
  };
  // UPDATE USER
  private static useLoginAPI = (): [string, any, (email: string, password: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (email: string, password: string) => {
      dispatch(setIsLoading(true));

      const method = 'POST';
      const endpoint = 'auth/login/';
      const data = JSON.stringify({
        'email': email,
        'password': password
      });

      const networkResponse = await Network.fetchAPI(method, endpoint, false, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        await Storage.set(Storage.AUTH_TOKEN, networkResponse.data.key);
        setResponse(networkResponse.data.key);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };
  // FORGOT PASSWORD
  private static useRegisterAPI = (): [string, any, (pendingSignUpUser: PendingUser) => void] => {
    const [stateResponse, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (pendingSignUpUser: PendingUser) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'auth/register/';
      const data = JSON.stringify(pendingSignUpUser);

      const response = await Network.fetchAPI(method, endpoint, false, data, null);
      dispatch(setIsLoading(false));

      setError(response.error);

      if (response.data) {
        await Storage.set(Storage.AUTH_TOKEN, response.data.key);
        setResponse(response.data.key);
      }
      if (response.error500) {
        dispatch(setError500(true));
      }
    };

    return [stateResponse, error, request];
  };

}

export default UserService;
