import React, {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IUserReducer, updatePendingUser} from '../../store/reducers/UserReducer';
import strings from '../../values/strings';
import {FormFields} from '../../values/values';
import {IStepFive} from './interface/SignupInterface';
import InputAutosuggest from '../../components/common/AutoSuggest';
import UserService from '../../services/UserServices';
import SelectOption from '../../store/types/SelectionOption';

const StepFifth: React.FC<IStepFive> = ({city, aiga_section, next}) => {

  const pendingSignUpUser = useSelector((state: IUserReducer) => state.user.pendingSignUpUser);
  const [suggCity, , requestCity] = UserService.useSearcCitiesAPI();
  const [suggSection, , requestSection] = UserService.useSearchSectionsAPI();
  const [cityInput, setCityInput] = useState('');
  const [sectionInput, setSectionInput] = useState('');
  const dispatch = useDispatch();

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  useEffect(() => {
    if (pendingSignUpUser && pendingSignUpUser.profile) {
      if (pendingSignUpUser.profile.city) {
        setCityInput(pendingSignUpUser.profile.city.name || '');
      }
      if (pendingSignUpUser.profile.aiga_section) {
        setSectionInput(pendingSignUpUser.profile.aiga_section.name || '');
      }
    }
  }, []);

  return (
    <form onSubmit={preventSubmit(next)}>
      <h1>{strings.signUp.headerTitle}</h1>
      <InputAutosuggest
        label={strings.fieldLabelCity}
        placeholder={strings.signUp.fifth.cityPlaceholder}
        suggestions={suggCity || []}
        onChange={(value) => {
          setCityInput(value);
          if (!value) {
            dispatch(updatePendingUser(FormFields.profile, {city: undefined}));
          }
        }}
        autoFocus={true}
        value={cityInput}
        onFetch={requestCity}
        onSelect={(sugg: SelectOption) => {
          dispatch(updatePendingUser(FormFields.city, sugg.value));
          if (sugg) {
            city.setValidity(true);
          }
        }}
        onBlur={(s) => {
          if (!pendingSignUpUser!.profile || (pendingSignUpUser!.profile && !pendingSignUpUser!.profile.city)) {
            setCityInput('');
            city.setValidity(false);
          } else {
            if (!s){
              setCityInput(pendingSignUpUser!.profile!.city!.name || '');
            }
          }
        }}
        error={!city.valid ? strings.errors.invalidField : undefined}
      />
      <InputAutosuggest
        label={strings.fieldLabelSection}
        placeholder={strings.signUp.fifth.sectionPlaceholder}
        suggestions={suggSection || []}
        onChange={(value) => {
          setSectionInput(value);
          if (!value) {
            dispatch(updatePendingUser(FormFields.profile, {aiga_section: undefined}));
          }
        }}
        value={sectionInput}
        onFetch={requestSection}
        onSelect={(sugg: SelectOption) => {
          dispatch(updatePendingUser(FormFields.section, sugg.value));
          if (sugg) {
            aiga_section.setValidity(true);
          }
        }}
        onBlur={(s) => {
          if (!pendingSignUpUser!.profile || (pendingSignUpUser!.profile && !pendingSignUpUser!.profile.aiga_section)) {
            setSectionInput('');
            aiga_section.setValidity(false);
          } else {
            if (!s){
              setSectionInput(pendingSignUpUser!.profile!.aiga_section!.name || '');
            }
          }
        }}
        error={!aiga_section.valid ? strings.errors.invalidField : undefined}
      />
      <button type={'submit'} className={'button-placeholder-submit'} tabIndex={-1} />
    </form>
  );
};

export default StepFifth;
