import React, {MouseEvent} from 'react';
import {useHistory} from 'react-router-dom';
import logo from '../image/logo-aiga.svg';
import strings from '../values/strings';


const Onboarding: React.FC = () => {
  const history = useHistory();

  // FIXME: You can replace it with <Link />
  function goToSignIn(event: MouseEvent) {
    event.preventDefault();
    history.push('signin');
  }

  function goToSignUp(event: MouseEvent) {
    event.preventDefault();
    history.push('SignUp');
  }

  return (
    <div className={'onboarding-cntr'}>
      <div className={'header-onboarding'}>
        <img className={'logo'} src={logo} alt="Logo aiga" />
        <p>{strings.onBoarding.first.subtitle} {strings.onBoarding.first.text}</p>
      </div>
      <div className={'wrapper-on'}>
        <div className={'onboarding'}>
          <div className={'onboarding__content'}>
            <h2>{strings.onBoarding.second.pre}</h2>
            <h1>{strings.onBoarding.second.title}</h1>
            <p>{strings.onBoarding.second.text}</p>
          </div>
          <div className={'onboarding__content'}>
            <h2>Creazione del verbale d'udienza?</h2>
            <h1>Sarai veloce come un razzo.</h1>
            <p>Crea il verbale ovunque ti trovi, invita i tuoi colleghi a lavorare sullo stesso documento da
              qualsiasi dispositivo: telefono, tablet o computer.</p>
          </div>
          <div className={'onboarding__content'}>
            <h1 style={{marginBottom: 40, paddingBottom: 0}}>Cosa aspetti?</h1>
            <button className={'button-main'} onClick={goToSignUp}>{strings.onBoarding.signUp}</button>
            <button className={'button-login'} onClick={goToSignIn}>{strings.onBoarding.signIn}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
