import Office from './Office';
import City from './City';

export class Profile {
  skills: any[] = [];
  type: string = 'LAWYER';
  city?: City;
  aiga_section?: Office;

  constructor(parameters?: Partial<Profile>) {
    if (parameters) {

      const {city, aiga_section, ...object} = parameters;

      Object.assign(this, object);
      if (city) {
        this.city = new City(city);
      }
      if (aiga_section) {
        this.aiga_section = new Office(aiga_section);
      }
    }

  }
}
