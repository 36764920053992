import React, {FormEvent, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';

import {IUserReducer, updatePendingUser} from '../../store/reducers/UserReducer';
import {FormFields, ExternalLinks} from '../../values/values';
import strings from '../../values/strings';
import Input from '../../components/common/Input';
import Utility from '../../utils/utils';
import {IStepSecond} from './interface/SignupInterface';

import closeTooltip from '../../image/icons/close.svg';

const StepSecond: React.FC<IStepSecond> = ({email, password, unHidePassword, next}) => {

  const pendingSignUpUser = useSelector((state: IUserReducer) => state.user.pendingSignUpUser);
  const dispatch = useDispatch();

  const [showTooltip, setShowTooltip] = useState(false);
  const [oulineCheckboxTerms, setOutlineCheckboxTerms] = useState(false);
  const [oulineCheckboxPrivacy, setOutlineCheckboxPrivacy] = useState(false);
  const [oulineCheckboxGDPR, setOutlineCheckboxGDPR] = useState(false);

  const toggleTerms = (force = false) => {
    dispatch(updatePendingUser(FormFields.terms, force || !pendingSignUpUser!.terms));
  };

  const togglePrivacy = (force = false) => {
    dispatch(updatePendingUser(FormFields.privacy, force || !pendingSignUpUser!.privacy));
  };

  // const toggleGDPR = (force = false) => {
  //   dispatch(updatePendingUser(FormFields.gdpr, force || !pendingSignUpUser!.gdpr));
  // };

  const openTooltip = () => {
    setShowTooltip(true);
  };

  const unHide = () => {
    unHidePassword.setValidity(!unHidePassword.valid);
  };

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  return (
    <form onSubmit={preventSubmit(next)}>
      <h1>{strings.signUp.headerTitle}</h1>

      <Input
        label={strings.fieldLabelEmailRegister}
        type="email"
        autoFocus={true}
        placeholder={strings.fieldPlaceholderEmail}
        value={pendingSignUpUser!.email}
        onChange={
          (event?: React.ChangeEvent<HTMLInputElement>) => {
            if (event) {
              dispatch(updatePendingUser(FormFields.email, event.target.value));
              if (Utility.validateEmail(event.target.value)) {
                email.setValidity(true);
              }
            }
          }
        }
        onBlur={
          (event?: React.FocusEvent<HTMLInputElement>) => {
            if (event) {
              email.setValidity(Utility.validateEmail(event.target.value));
            }
          }
        }
        error={!email.valid ? strings.errors.invalidEmail : undefined}
      />

      <div className={'flex-row'}>
        <label>{strings.fieldLabelPasswordRegister}</label>
        <span className={'button-help'} onClick={openTooltip} />
        {showTooltip && (
          <div className={'help-tooltip'}>
            <p className={'title'}>{strings.tooltip.passwordTitle}</p>
            <p className={'copy'}>{strings.tooltip.passwordText}</p>
            <div className={'close'} onClick={() => setShowTooltip(false)}>
              <img src={closeTooltip} alt="close" />
            </div>
          </div>
        )}
      </div>
      <div className={'password-container'}>
        <span className={classNames('unhide', {hide: unHidePassword.valid})} onClick={unHide} />
        <Input
          type={!unHidePassword.valid ? 'password' : 'text'}
          placeholder={strings.fieldPlaceholderPassword}
          value={pendingSignUpUser!.password}
          onChange={
            (event?: React.ChangeEvent<HTMLInputElement>) => {
              if (event) {
                dispatch(updatePendingUser(FormFields.password, event.target.value));
                if (Utility.validatePassword(event.target.value)) {
                  password.setValidity(true);
                }
              }
            }
          }
          onBlur={
            (event?: React.FocusEvent<HTMLInputElement>) => {
              if (event) {
                password.setValidity(Utility.validatePassword(event.target.value));
              }
            }
          }
          error={!password.valid ? strings.errors.invalidPassword : undefined}
        />
      </div>

      <div className={'checkbox-container'}>
        <div className={'checkbox-content'}>
          <div
            className={classNames('check', {checked: pendingSignUpUser!.terms, outline: oulineCheckboxTerms})}
            onClick={() => toggleTerms()}
          >
            <input
              defaultChecked={pendingSignUpUser!.terms}
              type={'checkbox'}
              onFocus={() => setOutlineCheckboxTerms(true)}
              onBlur={() => setOutlineCheckboxTerms(false)}
            />
            <span />
          </div>
          <a href={ExternalLinks.Terms} target={'_blank'} tabIndex={-1}
             onClick={() => toggleTerms()}>{strings.fieldLabelTerms}</a>
        </div>
        <div className={'checkbox-content'}>
          <div className={classNames('check', {checked: pendingSignUpUser!.privacy, outline: oulineCheckboxPrivacy})}
               onClick={() => togglePrivacy()}>
              <input
                defaultChecked={pendingSignUpUser!.privacy}
                type={'checkbox'}
                onFocus={() => setOutlineCheckboxPrivacy(true)}
                onBlur={() => setOutlineCheckboxPrivacy(false)}
              />
            <span />
          </div>
          <a href={ExternalLinks.Privacy} target={'_blank'} tabIndex={-1}
             onClick={() => togglePrivacy()}>{strings.fieldLabelPrivacy}</a>
        </div>
        {/* <div className={'checkbox-content'}>
          <div className={classNames('check', {checked: pendingSignUpUser!.gdpr, outline: oulineCheckboxGDPR})}
               onClick={() => toggleGDPR()}>
            <input
              defaultChecked={pendingSignUpUser!.gdpr}
              type={'checkbox'}
              onFocus={() => setOutlineCheckboxGDPR(true)}
              onBlur={() => setOutlineCheckboxGDPR(false)}
            />
            <span />
          </div>
          <a href={'https://google.com'} target={'_blank'} tabIndex={-1}
             onClick={() => toggleGDPR()}>{strings.fieldLabelGPDR}</a>
        </div> */}
      </div>

      <button type={'submit'} className={'button-placeholder-submit'} tabIndex={-1}/>
    </form>
  );
};

export default StepSecond;
