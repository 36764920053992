import React from 'react';
import logo from '../image/logo-aiga.svg';

const Loading: React.FC = () => {

  return (
    <div className={'loading-wrapper'}>
      <div className={'logo-container'}>
        <img className={'logo'} src={logo} alt="Logo aiga"/>
      </div>
      <h1>Loading</h1>
      <div className={'loader'}/>
    </div>
  );
};

export default Loading;
