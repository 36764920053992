import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {
  detailsAlert,
  detailsCourt,
  detailsJudge,
  detailsNote,
  detailsNrg,
  detailsOut,
  detailsTodo,
  greyArrow,
  returns
} from '../../image/icons';
import { DateFormat, EventStatus, FormFields, ResourceType } from '../../values/values';
import strings from '../../values/strings';
import { useSelector } from 'react-redux';
import { IAgendaReducer } from '../../store/reducers/AgendaReducer';
import Event from '../../store/types/Event';
import EventsService from '../../services/EventService';

interface ICommitmentDetailEventContent {
  title: string;
  icon?: string;
  property?: any;
}

const CommitmentDetailEventContent: React.FC<ICommitmentDetailEventContent> = ({ title, icon, property }) => {
  if (!property) {
    return null;
  }
  return (
    <div className={'content'}>
      <div className={'icon'}>
        {icon && <img src={icon} alt="icon" />}
      </div>
      <div className={'title'}>
        <p>{title}</p>
      </div>
      <div className={'copy'}>
        <p>{property}</p>
      </div>
    </div>
  );
};

const CommitmentDetailEvent: React.FC = () => {

  const currentEvent = useSelector((state: IAgendaReducer) => state.agenda.event);

  const [, , updateStatusRequest] = EventsService.useUpdateEventStatusAPI();

  const [event, setEvent] = useState<Event | undefined>();

  useEffect(() => {
    if (currentEvent && currentEvent.resourcetype === ResourceType.Notice && currentEvent.fulfillment instanceof Event) {
      setEvent(currentEvent.fulfillment);
    } else {
      setEvent(currentEvent);
    }
    return () => setEvent(undefined);
  }, [currentEvent]);

  if (!event) {
    return null;
  }

  return (
    <div className={'agenda__detail__detail'}>
      <div className={classNames('title-container', {
        app: event.resourcetype === ResourceType.Appointment,
        udi: event.resourcetype === ResourceType.Hearing,
        ade: event.resourcetype === ResourceType.Fulfillment || event.resourcetype === ResourceType.Notice
      })}>
        {(event.lawyerevent.status === EventStatus.DONE || event.lawyerevent.status === EventStatus.TODO) && (
          <div className={'check-container'}>
            <div
              className={classNames('check', { checked: event.lawyerevent.status === EventStatus.DONE })}
              onClick={() => updateStatusRequest(event, event.lawyerevent.status === EventStatus.DONE ? EventStatus.TODO : EventStatus.DONE)}
            />
          </div>
        )}
        {(event.lawyerevent.status === EventStatus.POSTPONED) && (
          <div className={'postponed'}>
            <img src={returns} alt="return" />
          </div>
        )}
        <p className={'title'}>{event.display_name}</p>
        <p className={'time'}>
          {event.all_day && strings.agenda.add.allDay}
          {!event.all_day && <>{event.start}<span><img src={greyArrow} alt="arrow" /></span>{event.end}</>}
        </p>
        <p className={'date'}>
          {moment(event.date).format(DateFormat.dayWithNumberAndMounth)}
          <span>
            {(event.resourcetype === ResourceType.Fulfillment || event.resourcetype === ResourceType.Notice) && 'Adempimento'}
            {event.resourcetype === ResourceType.Hearing && 'Udienza'}
            {event.resourcetype === ResourceType.Appointment && 'Appuntamento'}
          </span>
        </p>
      </div>
      <CommitmentDetailEventContent
        icon={detailsTodo}
        title={strings.agenda.event.items.activity}
        property={event.lawyerevent.activity}
      />
      {(event.resourcetype === ResourceType.Fulfillment && event.noticeDays()) && (
        <CommitmentDetailEventContent
          icon={detailsAlert}
          title={strings.agenda.event.items.notice}
          property={event.noticeDays()}
        />
      )}
      <CommitmentDetailEventContent
        icon={detailsNrg}
        title={strings.agenda.event.items.nrg}
        property={event.getResource(FormFields.nrg)}
      />
      {(event.resourcetype === ResourceType.Appointment) && (
        <>
          <CommitmentDetailEventContent
            icon={detailsOut}
            title={strings.agenda.event.items.expence1}
            property={event.getResource(FormFields.expense1)}
          />
          <CommitmentDetailEventContent
            title={strings.agenda.event.items.expence2}
            property={event.getResource(FormFields.expense2)}
          />
          <CommitmentDetailEventContent
            title={strings.agenda.event.items.expence3}
            property={event.getResource(FormFields.expense3)}
          />
        </>
      )}
      <CommitmentDetailEventContent
        icon={detailsNote}
        title={strings.agenda.event.items.notes}
        property={event.getResource(FormFields.notes)}
      />
      <CommitmentDetailEventContent
        icon={detailsJudge}
        title={strings.agenda.event.items.judge}
        property={event.lawyerevent.judge}
      />
      <CommitmentDetailEventContent
        icon={detailsCourt}
        title={strings.agenda.event.items.office}
        property={event.lawyerevent.office && event.lawyerevent.office.name}
      />
    </div>
  );
};

export default CommitmentDetailEvent;


