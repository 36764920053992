import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ResourceType} from '../../values/values';
import {setFilters} from '../../store/reducers/AgendaReducer';
import classNames from 'classnames';

const CommitmentFilters: React.FC = () => {
  const dispatch = useDispatch();
  const [filters, setFiltersState] = useState({
    [ResourceType.Fulfillment]: false,
    [ResourceType.Appointment]: false,
    [ResourceType.Notice]: false,
    [ResourceType.Hearing]: false,
    [ResourceType.News]: false
  });
  const toggleFilters = useCallback((type: ResourceType) => {
    setFiltersState({...filters, [type]: !filters[type]});
  }, [filters]);

  useEffect(() => {
    dispatch(setFilters(filters));
    return () => {
      dispatch(setFilters());
    };
  }, [filters]);
  return (
    <div className={'commitment__filter'}>
      <button
        className={classNames('button-filter app', {active: filters[ResourceType.Appointment]})}
        onClick={() => {toggleFilters(ResourceType.Appointment);}}
      >Appuntamenti</button>
      <button
        className={classNames('button-filter ade', {active: filters[ResourceType.Fulfillment]})}
        onClick={() => {toggleFilters(ResourceType.Fulfillment);}}
      >Adempimenti</button>
      <button
        className={classNames('button-filter avv', {active: filters[ResourceType.Notice]})}
        onClick={() => {toggleFilters(ResourceType.Notice);}}
      >Avvisi</button>
      <button
        className={classNames('button-filter udi', {active: filters[ResourceType.Hearing]})}
        onClick={() => {toggleFilters(ResourceType.Hearing);}}
      >Udienze</button>
    </div>
  );
};

export default CommitmentFilters;
