import React, {FormEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IUserReducer, updatePendingUser} from '../../store/reducers/UserReducer';
import strings from '../../values/strings';
import Input from '../../components/common/Input';
import {FormFields} from '../../values/values';
import Utility from '../../utils/utils';
import {IStepFourth} from './interface/SignupInterface';

const StepFourth: React.FC<IStepFourth> = ({phone, next}) => {

  const pendingSignUpUser = useSelector((state: IUserReducer) => state.user.pendingSignUpUser);
  const dispatch = useDispatch();

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  return (
    <form onSubmit={preventSubmit(next)}>
      <h1>{strings.signUp.headerTitle}</h1>
      <Input
        label={strings.fieldLabelPhone}
        placeholder={strings.fieldPlaceholderPhone}
        value={pendingSignUpUser!.phone}
        autoFocus={true}
        onChange={
          (event?: React.ChangeEvent<HTMLInputElement>) => {
            if (event) {
              dispatch(updatePendingUser(FormFields.phone, event.target.value));
              if (Utility.validatePhone(event.target.value)) {
                phone.setValidity(true);
              }
            }
          }
        }
        onBlur={
          (event?: React.FocusEvent<HTMLInputElement>) => {
            if (event) {
              phone.setValidity(Utility.validatePhone(event.target.value));
            }
          }
        }
        error={!phone.valid ? strings.errors.invalidPhone : undefined}
      />
      <button type={'submit'} className={'button-placeholder-submit'} tabIndex={-1}/>
    </form>
  );
};

export default StepFourth;
