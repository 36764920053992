import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../common/Input';
import profileImage from '../../image/profile__image.svg';
import strings from '../../values/strings';
import { User } from '../../store/types/User';
import {
  createPendingProfileUser,
  emptyPendingUser,
  IUserReducer, setShowUnder46,
  updatePendingUser
} from '../../store/reducers/UserReducer';
import { DateFormat, ExternalLinks, FormFields } from '../../values/values';
import { IAppReducer } from '../../store/reducers/AppReducer';
import UserService from '../../services/UserServices';
import { ISignUpError } from '../../page/signup/interface/SignupInterface';
import Utility from '../../utils/utils';
import classNames from 'classnames';
import moment from 'moment';
import InputAutosuggest from '../common/AutoSuggest';
import SelectOption from '../../store/types/SelectionOption';
import Under46Modal from './Under46Modal';

interface IProfile {
  currentUser: User;
}

const Profile: React.FC<IProfile> = ({ currentUser }) => {

  const dispatch = useDispatch();
  const [inReview, setInReview] = useState(false);
  const pendingSignUpUser = useSelector((state: IUserReducer) => state.user.pendingSignUpUser);
  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);

  // Validation  field
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEMail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [unHidePassword, setUnHidePassword] = useState(false);
  const [isValidFiscalCode, setIsValidFiscalCode] = useState(true);
  const [isValidBirthDate, setIsValidBirthDate] = useState(true);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isValidCity, setIsValidCity] = useState(true);
  const [isValidAigaSection, setIsValidAigaSection] = useState(true);

  const initError: ISignUpError = {};
  const [serverError, setServerError] = useState(initError);

  const [user, error, request] = UserService.useUpdateUserAPI();
  const [boolChangePass, , requestUpdatePassword] = UserService.useChangePasswordAPI();

  const [suggCity, , requestCity] = UserService.useSearcCitiesAPI();
  const [suggSection, , requestSection] = UserService.useSearchSectionsAPI();
  const [cityInput, setCityInput] = useState(currentUser!.profile ? currentUser!.profile!.city!.name! : '');
  const [sectionInput, setSectionInput] = useState(currentUser!.profile ? currentUser!.profile!.aiga_section!.name! : '');

  const unHide = () => {
    setUnHidePassword(!unHidePassword);
  };

  useEffect(() => {
    if (pendingSignUpUser) {
      if (pendingSignUpUser.checkDateField()) {
        const valid = pendingSignUpUser.checkDateValidity();
        setIsValidBirthDate(valid);
        if (valid) {
          const { birth_date: _, ...rest } = serverError;
          setServerError(rest);
        }
      } else {
        setIsValidBirthDate(false);
      }
    }
  }, [pendingSignUpUser]);

  useEffect(() => {
    if (typeof boolChangePass !== 'undefined') {
      if (!boolChangePass) {
        setIsValidPassword(false);
      }
    }
  }, [boolChangePass]);

  useEffect(() => {
    if (user) {
      setInReview(false);
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      Object.keys(error).forEach((key) => {
        switch (key) {
          case FormFields.first_name:
            setIsValidFirstName(false);
            break;
          case FormFields.last_name:
            setIsValidLastName(false);
            break;
          case FormFields.email:
            setIsValidEmail(false);
            break;
          case FormFields.password1:
            setIsValidPassword(false);
            break;
        }
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(createPendingProfileUser(currentUser!));
    const momentDate = moment(currentUser.birth_date, DateFormat.server);
    dispatch(updatePendingUser(FormFields.birth_day, momentDate.format('DD')));
    dispatch(updatePendingUser(FormFields.birth_month, +momentDate.format('MM') - 1));
    dispatch(updatePendingUser(FormFields.birth_year, momentDate.format('YYYY')));
    return () => {
      dispatch(emptyPendingUser());
    };
    // const momentBirthDateUser = moment(currentUser.birth_date, DateFormat.server);
  }, []);

  useEffect(() => {
    if (error) {
      setServerError({ ...error });
    }
  }, [error]);

  if (!pendingSignUpUser) {
    return null;
  }

  const preventSubmit = () => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

    };
  };

  const isValid = isValidFirstName && isValidLastName && isValidPassword && isValidEMail && isValidBirthDate && isValidFiscalCode && isValidNumber && isValidAigaSection && isValidCity;

  return (
    <>
      <form className={'profile'} onSubmit={preventSubmit}>
        <div className={'image'}>
          <img src={profileImage} alt="avatar" />
        </div>

        <div className={'col'}>
          <p>{strings.profile.headerTitle}</p>

          <div className={'row'}>
            <div className={'cell'}>
              <Input
                label={strings.fieldLabelFirstNameSummary}
                placeholder={strings.fieldPlaceholderFirstName}
                value={pendingSignUpUser!.first_name}
                readOnly={isLoading || !inReview}
                disabled={!inReview}
                onChange={
                  (event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      dispatch(updatePendingUser(FormFields.first_name, event.target.value));
                      if (event.target.value.length > 0) {
                        setIsValidFirstName(true);
                        const { first_name: _, ...rest } = serverError;
                        setServerError(rest);
                      }
                    }
                  }
                }
                onBlur={
                  (event?: React.FocusEvent<HTMLInputElement>) => {
                    if (event) {
                      setIsValidFirstName(pendingSignUpUser!.first_name.length > 0);
                    }
                  }
                }
                error={!isValidFirstName ? (serverError.first_name ? serverError.first_name : strings.errors.invalidField) : undefined}
              />
            </div>
            <div className={'cell'}>
              <Input
                label={strings.fieldLabelLastNameSummary}
                placeholder={strings.fieldPlaceholderLastName}
                value={pendingSignUpUser!.last_name}
                readOnly={isLoading || !inReview}
                disabled={!inReview}
                onChange={
                  (event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      dispatch(updatePendingUser(FormFields.last_name, event.target.value));
                      if (event.target.value.length > 0) {
                        setIsValidLastName(true);
                        const { last_name: _, ...rest } = serverError;
                        setServerError(rest);
                      }
                    }
                  }
                }
                onBlur={
                  (event?: React.FocusEvent<HTMLInputElement>) => {
                    if (event) {
                      setIsValidLastName(pendingSignUpUser!.last_name.length > 0);
                    }
                  }
                }
                error={!isValidLastName ? (serverError.last_name ? serverError.last_name : strings.errors.invalidField) : undefined}
              />
            </div>
          </div>

          <div className={'row'}>
            <div className={'cell'}>
              <Input
                label={strings.fieldLabelEmailSummary}
                placeholder={strings.fieldPlaceholderEmail}
                readOnly={isLoading || !inReview}
                disabled={!inReview}
                value={pendingSignUpUser!.email}
                onChange={
                  (event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      dispatch(updatePendingUser(FormFields.email, event.target.value));
                      if (Utility.validateEmail(event.target.value)) {
                        setIsValidEmail(true);
                        const { email: _, ...rest } = serverError;
                        setServerError(rest);
                      }
                    }
                  }
                }
                onBlur={
                  (event?: React.FocusEvent<HTMLInputElement>) => {
                    if (event) {
                      setIsValidEmail(Utility.validateEmail(event.target.value));
                    }
                  }
                }
                error={!isValidEMail ? (serverError.email ? serverError.email : strings.errors.invalidEmail) : undefined}
              />
            </div>
            <div className={'cell'}>
              <div className={'password-container'}>
                {inReview && <span className={classNames('unhide', { hide: unHidePassword })} onClick={unHide} />}
                <Input
                  label={strings.fieldLabelPasswordSummary}
                  type={!inReview ? 'password' : (!unHidePassword ? 'password' : 'text')}
                  placeholder={strings.fieldPlaceholderPassword}
                  readOnly={isLoading || !inReview}
                  disabled={!inReview}
                  value={inReview ? pendingSignUpUser!.password : strings.profile.passwordHidden}
                  onChange={
                    (event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) {
                        dispatch(updatePendingUser(FormFields.password, event.target.value));
                        if (event.target.value.length > 0) {
                          if (Utility.validatePassword(event.target.value)) {
                            setIsValidPassword(true);
                            const { password1: _, ...rest } = serverError;
                            setServerError(rest);
                          }
                        } else {
                          setIsValidPassword(true);
                        }
                      }
                    }
                  }
                  onBlur={
                    (event?: React.FocusEvent<HTMLInputElement>) => {
                      if (event) {
                        if (event.target.value.length > 0) {
                          setIsValidPassword(Utility.validatePassword(event.target.value));
                        } else {
                          setIsValidPassword(true);
                        }
                      }
                    }
                  }
                  error={!isValidPassword ? (serverError.password1 ? serverError.password1 : strings.errors.invalidPassword) : undefined}
                />
              </div>
            </div>
          </div>

          <div className={'row'}>
            <div className={'cell'}>
              <label className={'full'}>{strings.fieldLabelBirthDateSummary}</label>
              <div className={'date-container'}>
                <input
                  placeholder={'GG'}
                  readOnly={isLoading || !inReview}
                  value={pendingSignUpUser!.birth_day}
                  disabled={isLoading || !inReview}
                  onChange={
                    (event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) {
                        if (!event.target.value) {
                          dispatch(updatePendingUser(FormFields.birth_day, ''));
                        } else if (!isNaN(Number(event.target.value))) {
                          const value = +event.target.value;
                          if (value < 1) {
                            dispatch(updatePendingUser(FormFields.birth_day, '1'));
                          } else if (value > 31) {
                            dispatch(updatePendingUser(FormFields.birth_day, '31'));
                          } else {
                            dispatch(updatePendingUser(FormFields.birth_day, event.target.value));
                          }
                        }
                      }
                    }
                  }
                  onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                    event.target.select();
                  }}
                />
                <div className={classNames('select-container', { 'read-only': (isLoading || !inReview) })}>
                  <span />
                  <select
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      dispatch(updatePendingUser(FormFields.birth_month, event.target.value));
                    }}
                    value={pendingSignUpUser!.birth_month}
                    disabled={isLoading || !inReview}
                  >
                    <option disabled={true} value={'-1'}> -- mese --</option>
                    <option value={'0'}>Gennaio</option>
                    <option value={'1'}>Febbraio</option>
                    <option value={'2'}>Marzo</option>
                    <option value={'3'}>Aprile</option>
                    <option value={'4'}>Maggio</option>
                    <option value={'5'}>Giugno</option>
                    <option value={'6'}>Luglio</option>
                    <option value={'7'}>Agosto</option>
                    <option value={'8'}>Settembre</option>
                    <option value={'9'}>Ottobre</option>
                    <option value={'10'}>Novembre</option>
                    <option value={'11'}>Dicembre</option>
                  </select>
                </div>
                <input type="text" placeholder={'AAAA'}
                  value={pendingSignUpUser!.birth_year}
                  readOnly={isLoading || !inReview}
                  disabled={isLoading || !inReview}
                  onChange={
                    (event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) {
                        if (!event.target.value) {
                          dispatch(updatePendingUser(FormFields.birth_year, ''));
                        } else if (!isNaN(Number(event.target.value))) {
                          dispatch(updatePendingUser(FormFields.birth_year, event.target.value));
                        }
                      }
                    }
                  }
                  onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                    event.target.select();
                  }}
                />
              </div>
              {!isValidBirthDate && <p className={'error'}>{strings.errors.invalidDate}</p>}
            </div>
            <div className={'cell'}>
              <Input
                label={strings.fieldLabelFiscalCodeSummary}
                placeholder={strings.fieldPlaceholderFiscalCode}
                readOnly={isLoading || !inReview}
                disabled={!inReview}
                value={pendingSignUpUser!.fiscal_code}
                onChange={
                  (event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      dispatch(updatePendingUser(FormFields.fiscal_code, event.target.value.toUpperCase()));
                      if (Utility.validateFiscalCode(event.target.value)) {
                        setIsValidFiscalCode(true);
                        const { fiscal_code: _, ...rest } = serverError;
                        setServerError(rest);
                      }
                    }
                  }
                }
                onBlur={
                  (event?: React.FocusEvent<HTMLInputElement>) => {
                    if (event) {
                      setIsValidFiscalCode(Utility.validateFiscalCode(event.target.value));
                    }
                  }
                }
                error={!isValidFiscalCode ? (serverError.fiscal_code ? serverError.fiscal_code : strings.errors.invalidFiscalCode) : undefined}
              />
            </div>
          </div>

          <div className={'row'}>
            <div className={'cell'}>
              <Input
                label={strings.fieldLabelPhoneSummary}
                placeholder={strings.fieldPlaceholderPhone}
                readOnly={isLoading || !inReview}
                disabled={!inReview}
                value={pendingSignUpUser!.phone}
                onChange={
                  (event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      dispatch(updatePendingUser(FormFields.phone, event.target.value));
                      if (Utility.validatePhone(event.target.value)) {
                        setIsValidNumber(true);
                        const { phone: _, ...rest } = serverError;
                        setServerError(rest);
                      }
                    }
                  }
                }
                onBlur={
                  (event?: React.FocusEvent<HTMLInputElement>) => {
                    if (event) {
                      setIsValidNumber(Utility.validatePhone(event.target.value));
                    }
                  }
                }
                error={!isValidNumber ? (serverError.phone ? serverError.phone : strings.errors.invalidPhone) : undefined}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'cell'}>
              {(isLoading || !inReview) && (
                <Input
                  label={strings.fieldLabelCity}
                  value={cityInput}
                  readOnly={isLoading || !inReview}
                  disabled={!inReview}
                  placeholder={strings.signUp.fifth.cityPlaceholder}
                  error={!isValidCity ? strings.errors.invalidField : undefined}
                />
              )}
              {!(isLoading || !inReview) && (
                <div style={{ width: '100%' }}>
                  <InputAutosuggest
                    readOnly={isLoading || !inReview}
                    label={strings.fieldLabelCity}
                    placeholder={strings.signUp.fifth.cityPlaceholder}
                    suggestions={suggCity || []}
                    onChange={(value) => {
                      setCityInput(value);
                      if (!value) {
                        dispatch(updatePendingUser(FormFields.profile, {
                          ...pendingSignUpUser.profile,
                          city: null
                        }));
                      }
                    }}
                    value={cityInput}
                    onFetch={requestCity}
                    onSelect={(sugg: SelectOption) => {
                      dispatch(updatePendingUser(FormFields.city, sugg.value));
                      if (sugg) {
                        setIsValidCity(true);
                      }
                    }}
                    onBlur={(s) => {
                      if (!pendingSignUpUser!.profile || (pendingSignUpUser!.profile && !pendingSignUpUser!.profile.city)) {
                        setCityInput('');
                        setIsValidCity(false);
                      } else {
                        if (!s) {
                          setCityInput(pendingSignUpUser!.profile!.city!.name || '');
                        }
                      }
                    }}
                    error={!isValidCity ? strings.errors.invalidField : undefined}
                  />
                </div>
              )}
            </div>
            <div className={'cell'}>
              {(isLoading || !inReview) && (
                <Input
                  label={strings.fieldLabelSection}
                  value={sectionInput}
                  readOnly={isLoading || !inReview}
                  disabled={!inReview}
                  placeholder={strings.signUp.fifth.sectionPlaceholder}
                  error={!isValidAigaSection ? strings.errors.invalidField : undefined}
                />
              )}
              {!(isLoading || !inReview) && (
                <div style={{ width: '100%' }}>
                  <InputAutosuggest
                    readOnly={isLoading || !inReview}
                    label={strings.fieldLabelSection}
                    placeholder={strings.signUp.fifth.sectionPlaceholder}
                    suggestions={suggSection || []}
                    onChange={(value) => {
                      setSectionInput(value);
                      if (!value) {
                        dispatch(updatePendingUser(FormFields.profile, {
                          ...pendingSignUpUser.profile,
                          aiga_section: null
                        }));
                      }
                    }}
                    value={sectionInput}
                    onFetch={requestSection}
                    onSelect={(sugg: SelectOption) => {
                      dispatch(updatePendingUser(FormFields.section, sugg.value));
                      if (sugg) {
                        setIsValidAigaSection(true);
                      }
                    }}
                    onBlur={(s) => {
                      if (!pendingSignUpUser!.profile || (pendingSignUpUser!.profile && !pendingSignUpUser!.profile.aiga_section)) {
                        setSectionInput('');
                        setIsValidAigaSection(false);
                      } else {
                        if (!s) {
                          setSectionInput(pendingSignUpUser!.profile!.aiga_section!.name || '');
                        }
                      }
                    }}
                    error={!isValidAigaSection ? strings.errors.invalidField : undefined}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={'row'} style={{ borderBottom: 0 }}>
            <div className={'cell'}>
              <a href={ExternalLinks.Terms}>{strings.fieldLabelTermsSecondary}</a>
              <a href={ExternalLinks.Privacy}>{strings.fieldLabelPrivacySecondary}</a>
              {/* <a href={ExternalLinks.GDPR}>{strings.fieldLabelGDPRSecondary}</a> */}
            </div>
          </div>

          <div className={'row'} style={{ borderBottom: 0 }}>
            <div className={'cell'} style={inReview ? { display: 'none' } : undefined} onClick={() => setInReview(true)}>
              <button className={'cancel'} type="button">{strings.profile.actionEdit}</button>
            </div>
            <div className={'cell'} style={{ justifyContent: 'flex-end' }}
              onClick={() => {
                if (isValid) {
                  const { first_name, last_name, password, phone, fiscal_code, email, birth_date, profile } = pendingSignUpUser;
                  if (password.length > 0) {
                    requestUpdatePassword(password);
                  }
                  request(new User({ first_name, last_name, email, birth_date, phone, fiscal_code, profile }));
                }
              }}>
              <button className={classNames('button-primary', { disabled: !isValid })} type={'button'}>
                <span className={classNames({ unvisible: isLoading })}>{strings.profile.edit.actionSave}</span>
                {isLoading && <span className={'button-loading'} />}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Profile;
