import {Profile} from './Profile';
import Utility from '../../utils/utils';

export class User {
  static fullName = (user: User) => {
    return user.first_name + ' ' + user.last_name;
  };
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  birth_date?: string;
  phone?: string;
  fiscal_code?: string;
  password1?: string;
  password2?: string;
  under46: boolean = false;
  terms: boolean = false;
  privacy: boolean = false;
  gdpr: boolean = false;
  profile?: Profile;

  constructor(parameters: Partial<User>) {
    const {first_name, last_name, email, profile, ...obj} = parameters;

    Object.assign(this, obj);

    this.first_name = first_name || '';
    this.last_name = last_name || '';
    this.email = email || '';

    if (profile) {
      this.profile = new Profile(profile);
    }

    if (this.birth_date && !Utility.isUnder46(this.birth_date)) {
      this.under46 = false;
    }
  }

  isUder46(): boolean {
    return this.under46;
  }
}
