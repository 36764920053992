import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import { add, linkArrow } from '../../image/icons';
import verbalIco from '../../image/verbal.svg';
import RecordsService from '../../services/RecordServices';
import { inRecordCreation, IRecordsReducer, selectRecord, setMineOrShared } from '../../store/reducers/RecordReducer';
import { DateFormat } from '../../values/values';
import strings from '../../values/strings';
import { IUserReducer } from '../../store/reducers/UserReducer';
import { isTablet } from 'react-device-detect';
import { tabThirdOpen } from '../../store/reducers/AppReducer';

const RecordWrapper: React.FC = () => {

  const [, , recordsRequest] = RecordsService.useRecordsAPI();
  const records = useSelector((state: IRecordsReducer) => state.record.records);
  const mineOrShared = useSelector((state: IRecordsReducer) => state.record.mineOrShared);
  const currentRecord = useSelector((state: IRecordsReducer) => state.record.record);
  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    recordsRequest();

    dispatch(selectRecord())

  }, []);

  return (
    <div className={'verbalCenter'}>
      <div className={'verbalCenter__header'}>
        <div className={'tab'}>
          <a className={classNames({ active: mineOrShared })} onClick={() => {
            dispatch(selectRecord());
            dispatch(setMineOrShared(true));
          }}>{strings.records.create}</a>
          {/*<a className={classNames({active: !mineOrShared})} onClick={() => {*/}
          {/*dispatch(selectRecord());*/}
          {/*dispatch(setMineOrShared(false));*/}
          {/*}}>{strings.records.share}</a>*/}
        </div>
        {(currentUser && currentUser.isUder46()) && (
          <div className={'flex-row'}>
            <button className={'button-secondary icon'} onClick={() => dispatch(inRecordCreation(true))}>
              <span className={'icon'}><img src={add} /></span>
              <span className={'txt'}>{strings.records.actionSheet.options.create}</span>
            </button>
          </div>
        )}
      </div>
      <div className={'verbalCenter__list'}>
        {records && records
          // .filter(r => mineOrShared ? r.user!.id === user!.id : r.user!.id !== user!.id)
          .filter(r => mineOrShared)
          .map((r) => (
            <div
              className={classNames('verbalCenter__list__item', { active: (currentRecord && r.id === currentRecord.id) })}

              key={r.id} onClick={() => {
                dispatch(selectRecord(r))
                dispatch(tabThirdOpen());
              }
              }>
              <div className={'icon'}>
                <img src={verbalIco} />
              </div>
              <div className={'content'}>
                <div className={'content__copy'}>
                  <p className={'user'}>
                    {r.identifier}
                  </p>
                  <p className={'date'}>
                    {moment(r.created_at).format(DateFormat.full)}
                  </p>
                  {/*TESTO PER CREATI*/}
                  <p className={'author'}>
                    <span className={'code'}>{r.code}</span>
                    <CopyToClipboard text={r.link!}>
                      <a onClick={(e) => e.stopPropagation()}>
                        {strings.records.link}
                      </a>
                    </CopyToClipboard>
                  </p>
                  {/*TESTo PER CONDIVISI*/}
                  {/*<p className={'author'}>*/}
                  {/*<span>*/}
                  {/*<span className={'di'}>di</span>*/}
                  {/*{r.user!.first_name} {r.user!.last_name}*/}
                  {/*</span>*/}
                  {/*</p>*/}
                </div>
                <div className={'content__icon'}>
                  <img src={linkArrow} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RecordWrapper;
