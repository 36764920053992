import React from 'react';
import logo from '../image/logo-aiga.svg';
import {Link} from 'react-router-dom';

const PageError: React.FC = () => {

  return (
    <div className={'page-error'}>
      <div className={'logo-container'}>
        <img className={'logo'} src={logo} alt="Logo aiga" />
      </div>
      <h1>404</h1>
      <p>Siamo spiacenti, pagina non trovata!</p>
      <p className={'small'}>La pagina che stai cercando non è al momento raggiungibile.</p>
      <p><Link to={'/'}>Torna in home</Link></p>
    </div>
  );
};

export default PageError;
