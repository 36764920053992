import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Onboarding from '../page/Onboarding';
import SignUp from '../page/SignUp';
import SignIn from '../page/SignIn';
import Forgot from '../page/Forgot';
import Loading from '../page/Loading';
import PageError from '../page/PageError';
import {useDispatch, useSelector} from 'react-redux';
import {IUserReducer} from '../store/reducers/UserReducer';
import UserService from '../services/UserServices';
import Storage from '../store/Storage';
import {IAppReducer, setIsReady} from '../store/reducers/AppReducer';
import AppWrapper from '../page/AppWrapper';
import Logout from '../page/Logout';

interface IAuthMiddlewarePropsExtends {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const AuthMiddleware = ({component: Component, ...rest}: IAuthMiddlewarePropsExtends) => {
  const authenticated = !!useSelector((state: IUserReducer) => state.user.currentUser);
  return (
    <Route {...rest} render={(props) => {
      if (authenticated) {
        return <Component {...props} />;
      }
      return <Redirect to={'/'} />;
    }} />
  );
};

const GuestMiddleware = ({component: Component, ...rest}: IAuthMiddlewarePropsExtends) => {
  const authenticated = !!useSelector((state: IUserReducer) => state.user.currentUser);
  return (
    <Route {...rest} render={(props) => {
      if (!authenticated) {
        return <Component {...props} />;
      }
      return <Redirect to={'/'} />;
    }} />
  );
};


function AppRouter() {
  const authenticated = !!useSelector((state: IUserReducer) => state.user.currentUser);
  const isReady = !!useSelector((state: IAppReducer) => state.app.isReady);

  const dispatch = useDispatch();

  const [user, , userRequest] = UserService.useUserAPI();

  useEffect(() => {
    loadStorageData();
  }, []);

  useEffect(() => {
    if (user && authenticated) {

      dispatch(setIsReady());
    }
  }, [user, authenticated]);

  const loadStorageData = async () => {
    const _authToken = await Storage.get(Storage.AUTH_TOKEN);
    if (!_authToken) {
      dispatch(setIsReady());
    } else {
      userRequest();
    }
  };
  if (!isReady) {
    return <Loading />;
  }
  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} render={() => {
          if (authenticated) {
            return <Redirect to={'agenda'} />;
          }
          return <Onboarding />;
        }} />
        <GuestMiddleware path="/signin" exact={true} component={SignIn} />
        <GuestMiddleware path="/forgot" exact={true} component={Forgot} />
        <AuthMiddleware path="/agenda" component={AppWrapper} />
        <AuthMiddleware path="/records" component={AppWrapper} />
        <AuthMiddleware path="/profile" component={AppWrapper} />
        <AuthMiddleware path="/logout" exact={true} component={Logout} />

        <Route path="/signup" exact={true} component={SignUp} />
        <Route path="/loading" exact={true} component={Loading} />
        <Route path="/404" exact={true} component={PageError} />
        <Route component={() => <Redirect to={'404'} />} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
