import {MenuItem} from '../types/MenuItem';
import Page from '../types/Page';

const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_IS_READY = 'SET_IS_READY';
const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
const TAB_THIRD_TOGGLE = 'OPEN_TAB_THIRD_TOGGLE';
const TAB_THIRD_OPEN = 'TAB_THIRD_OPEN';
const TAB_THIRD_CLOSE = 'TAB_THIRD_CLOSE';
const SET_PAGE = 'SET_PAGE';
const SET_ERROR_500 = 'SET_ERROR_500';

export interface IAppReducer {
  app: IAppReducerState;
}

export interface IAppReducerState {
  isLoading: boolean;
  isReady: boolean;
  menu?: MenuItem[];
  isOpenTabThird: boolean;
  page: Page;
  error500: boolean;
}

// ACTIONS
export function tabThirdToggle() {
  return async (dispatch: any) => {

    await dispatch({
      type: TAB_THIRD_TOGGLE
    });
  };
}

export function tabThirdOpen() {
  return async (dispatch: any) => {

    await dispatch({
      type: TAB_THIRD_OPEN
    });
  };
}

export function tabThirdClose() {
  return async (dispatch: any) => {
    await dispatch(setPage(Page.Empty));
    await dispatch({
      type: TAB_THIRD_CLOSE
    });
  };
}


export function setIsLoading(isLoading: boolean) {
  return async (dispatch: any) => {

    await dispatch({
      type: SET_IS_LOADING,
      isLoading
    });
  };
}

export function setIsReady() {
  return async (dispatch: any) => {

    await dispatch({
      type: SET_IS_READY
    });
  };
}

export function setMenuItems(items: MenuItem[]) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_MENU_ITEMS,
      items
    });
  };
}

export function setPage(page: Page) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PAGE,
      page
    });
  };
}

export function newCommitment() {
  return async (dispatch: any) => {
    await dispatch(setPage(Page.NewCommitment));
    await dispatch(tabThirdOpen());
  };
}

export function editCommitment() {
  return async (dispatch: any) => {
    await dispatch(setPage(Page.EditCommitment));
    await dispatch(tabThirdOpen());
  };
}

export function postPoneHearing() {
  return async (dispatch: any) => {
    await dispatch(setPage(Page.PostPoneHearing));
    await dispatch(tabThirdOpen());
  };
}

export function selectEvent() {
  return async (dispatch: any) => {
    await dispatch(setPage(Page.Commitment));
    await dispatch(tabThirdOpen());
  };
}
export function setError500(errorState: boolean) {
  return async (dispatch: any) => {
    return dispatch({type: SET_ERROR_500, errorState});
  };
}

const INITIAL_STATE: IAppReducerState = {
  isLoading: false,
  isReady: false,
  isOpenTabThird: false,
  page: Page.Empty,
  error500: false
};

// REDUCER
export default function (state: IAppReducerState = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case SET_IS_READY:
      return {
        ...state,
        isReady: true
      };
    case SET_MENU_ITEMS:
      return {
        ...state,
        menu: action.items.sort((a: MenuItem, b: MenuItem) => {
          if (!a.order) {
            return 999999;
          }
          return a.order;
        })
      };
    case TAB_THIRD_TOGGLE:
      return {
        ...state,
        isOpenTabThird: !state.isOpenTabThird
      };
    case TAB_THIRD_CLOSE:
      return {
        ...state,
        isOpenTabThird: false
      };
    case TAB_THIRD_OPEN:
      return {
        ...state,
        isOpenTabThird: true
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page
      };
    case SET_ERROR_500:
      return {
        ...state,
        error500: action.errorState
      };
    default:
      return state;
  }
}
