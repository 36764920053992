import React from 'react';
import {MenuItem} from '../../store/types/MenuItem';
import classNames from 'classnames';

interface IMenuElement {
  item: MenuItem;
  target?: string;
}

const MenuElement: React.FC<IMenuElement> = ({item, target}) => {
  return (
    <a href={item.link} target={target || item.target} className={classNames({'with-icon': !!item.icon})}>
      {item.icon && <img src={item.icon} />}
      <span dangerouslySetInnerHTML={{__html: item.title}}/>
    </a>
  );
};

export default MenuElement;
