import React, { Fragment, useEffect, useState, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';

import MenuService from '../services/MenuServices';
import CommitmentWrapper from '../components/commitment/CommitmentWrapper';
import AgendaDetail from '../components/commitment/CommitmentRightDetail';

import logo from '../image/logo-aiga.svg';
import collega from '../image/collega.svg';
import { Link, Route, Switch, useHistory, NavLink } from 'react-router-dom';
import MenuWrapper from '../components/menu/MenuWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { IAppReducer, selectEvent } from '../store/reducers/AppReducer';
import MenuElement from '../components/menu/MenuElement';
import { MenuItem } from '../store/types/MenuItem';
import strings from '../values/strings';
import Calendar from '../components/common/Calendar';
import { IAgendaReducer, setCurrentDay, setCurrentEvent } from '../store/reducers/AgendaReducer';
import { DateFormat, ExternalLinks, ResourceType } from '../values/values';
import RecordWrapper from '../components/record/RecordWrapper';
import RecordModal from '../components/record/RecordModal';
import { IRecordsReducer } from '../store/reducers/RecordReducer';
import RecordActionSection from '../components/record/RecordActionSection';
import Profile from '../components/profile/Profile';
import { IUserReducer, setShowUnder46 } from '../store/reducers/UserReducer';
import GlobalErrorModal from '../components/GlobalErrorModal';
import CommitmentDeleteModal from '../components/commitment/CommitmentDeleteModal';
import Under46Modal from '../components/profile/Under46Modal';
import NotificationService from '../services/NotificationService';
import { Notification } from '../store/types/Notification';
import { NotificationsReducer, resetNotificationsStore } from '../store/reducers/NotificationsReducer';
import { greyArrow } from '../image/icons';
import Event from '../store/types/Event';

var _ = require('lodash');

enum NotificationStatus {
  read = 'READ',
  unread = 'UNREAD'
}

const staticMenu: MenuItem[] = [
  {
    link: ExternalLinks.CollegaWebsite,
    title: 'Collega <sup>&#x000AE;</sup>',
    order: 2,
    target: '_blank',
    icon: collega
  },
  { link: ExternalLinks.Support, title: strings.drawer.support, order: 2 },
  { link: ExternalLinks.AIGAWebSite, title: strings.drawer.aiga, order: 3, target: '_blank' }
];


const AppWrapper: React.FC = () => {
  const [, , menuRequest] = MenuService.useMenuAPI();
  let [, , unreadNotificationsRequest] = NotificationService.useUnreadNotificationsAPI()
  let [notificationsResponse, , notificationsRequest] = NotificationService.useNotificationsAPI()
  let [, , readallRequest] = NotificationService.useNotificationsReadallAPI()
  let [notificationPage, setNotificationPage] = useState()

  let notifications = useSelector((state: NotificationsReducer) => state.notificationsReducer != undefined ? state.notificationsReducer.notifications : [])
  let unreadNotifications = useSelector((state: NotificationsReducer) => state.notificationsReducer.unreadNotifications)

  const errorGlobal = useSelector((state: IAppReducer) => state.app.error500);
  const isOver46 = useSelector((state: IUserReducer) => state.user.showUnder46);
  const menuItem = useSelector((state: IAppReducer) => state.app.menu);
  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const inModalCreation = useSelector((state: IRecordsReducer) => state.record.inCreation);
  const inModalDelete = useSelector((state: IAgendaReducer) => state.agenda.modalDeleteEvent);
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [isOpenNotificationsMenu, setIsOpenNotificationsMenu] = useState(false);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);

  const dispatch = useDispatch();

  let history = useHistory();


  useEffect(() => {
    menuRequest()
    unreadNotificationsRequest()

    if (currentUser) {
      if (!currentUser.isUder46()) {
        dispatch(setShowUnder46(true));
      }
    }
  }, []);

  useEffect(() => {
    setIsLoadingNotifications(false)
    if (!notificationsResponse) return
  }, [notificationsResponse]);


  let askForPage = async (page: number, reset?: boolean) => {
    if (reset) {
      await dispatch(resetNotificationsStore())
      // await setNotificationPage(1)
    }
    await setNotificationPage(page)
    await notificationsRequest(page)

    readallRequest()
  }

  let loadMore = async () => {
    if (_.isEmpty(notificationsResponse)) return
    askForPage(notificationPage + 1)
  }

  const openNotificationsMenu = async () => {
    setIsOpenNotificationsMenu(true);
    setIsLoadingNotifications(true)

    askForPage(1, true)
  };

  const closeNotificationsMenu = () => {
    setIsOpenNotificationsMenu(false);
  };

  const openContextMenu = () => {
    setIsOpenContextMenu(true);
  };

  const closeContextMenu = () => {
    setIsOpenContextMenu(false);
  };


  const setToday = () => {
    dispatch(setCurrentDay());
    setTimeout(() => {
      dispatch(setCurrentDay(moment(new Date()).format(DateFormat.server)));
    }, 1);
  };

  let openNotification = async (notification: Notification) => {

    if (notification.event) {
      closeNotificationsMenu()

      // Go to Agenda
      if (window.location.pathname !== '/agenda') {
        await history.push('/agenda')
      }

      // Open panel
      let _event = new Event(notification.event)
      if (_event.resourcetype !== ResourceType.News) {
        dispatch(selectEvent());
        dispatch(setCurrentEvent(_event));

      } else {
        if (_event.news) {
          const win = window.open(_event.news.link, '_blank');
          if (win) {
            win.focus();
          }
        }
      }

    } else if (notification.record) {
      closeNotificationsMenu()

      window.open(notification.record.link + '/' + notification.record.code, '_blank');
    }
  }



  let handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) loadMore()
  }

  // RENDER

  if (!currentUser) { return null }

  return (
    <Fragment>
      <div className={'agenda__header'}>
        <div className={'logo'}>
          <Link to={'/'}><img className={'logo'} src={logo} alt="Logo aiga" /></Link>
        </div>
        <div className={'cta-container'}>

          {/*NOTIFICATIONS*/}

          <div className={'cta-notifications'} onClick={openNotificationsMenu} >
            {unreadNotifications > 0 && <div className={'notification_badge'}>
              <span className={'notification_text'}>
                {unreadNotifications}
              </span>
            </div>}
          </div>


          {isOpenNotificationsMenu && (
            <OutsideClickHandler onOutsideClick={() => closeNotificationsMenu()}>
              <div className={ window.location.pathname !== '/agenda' ? 'menu-notifications menu-notifications-secondary' : 'menu-notifications'}>
                <div className={'menu-notifications-wrapper'} onScroll={handleScroll} >

                  {isLoadingNotifications &&
                    <div className={'notification_empty_state_container'}>
                      <div className={'notification_loading'} />
                    </div>}

                  {!isLoadingNotifications && _.isEmpty(notifications) &&
                    <div className={'notification_empty_state_container'}>
                      <span className={'empty_state_text'}>Non ci sono notifiche!</span>
                    </div>}

                  {notifications && !_.isEmpty(notifications) && notifications.map((notification: Notification) => {
                    return (
                      <div className={'container'} onClick={() => openNotification(notification)}>

                        <div className={'sub_container'}>
                          <div className={'notification_item_container'} >

                            <span className={'date'}>{moment(notification.created_at).calendar()}</span>
                            <span className={notification.status === NotificationStatus.unread ? 'unread' : 'read'}>{notification.title}</span>
                            {notification.subtitle && <span className={'subtitle'}>{notification.subtitle}</span>}

                          </div>
                          <img src={greyArrow} />
                        </div>

                        <div className={'divider'}></div>

                      </div>
                    )
                  })}
                </div>
              </div>
            </OutsideClickHandler>
          )}

          <Route path="/agenda">
            <button className={'button-secondary'} onClick={setToday}>Oggi</button>
          </Route>

          {/*MENU PROFILO*/}
          <div className={'cta-profile'} onClick={openContextMenu} />
            
          {isOpenContextMenu && (
            <OutsideClickHandler onOutsideClick={() => closeContextMenu()}>
              <div  className={ window.location.pathname !== '/agenda' ? 'menu-profile menu-profile-secondary' : 'menu-profile'}>
                <ul>
                  <Switch>
                    <Route path="/profile">
                      <li>
                        <Link to="agenda" onClick={() => {
                          closeContextMenu();
                        }}>Torna all'agenda</Link>
                      </li>
                    </Route>
                    <Route path="*">
                      <li>
                        <Link to="profile" onClick={() => {
                          closeContextMenu();
                        }}>Vai al profilo</Link>
                      </li>
                    </Route>
                  </Switch>
                  <li>
                    <Link to="logout">Logout</Link>
                  </li>
                </ul>
              </div>
            </OutsideClickHandler>
          )}

        </div>
      </div>
      <Switch>
        <Route path="/profile">
          <Profile currentUser={currentUser} />
        </Route>
        <Route path={'*'}>
          <div className={'agenda'}>
            <div className={'agenda__agenda'}>

              <Switch>
                <Route path="/agenda">
                  <h1>{strings.drawer.agenda}</h1>
                  <div className={'agenda__agenda__content'}>
                    <div className={'agenda__calendar'}>
                      <Calendar />
                    </div>
                    <Link className={'cta-verbale'} to="records">{strings.drawer.records}</Link>
                    <MenuWrapper>
                      {staticMenu.map((item) => (<MenuElement key={item.title} item={item} />))}
                    </MenuWrapper>

                    {menuItem && (
                      <MenuWrapper>
                        {menuItem.map((item) => (<MenuElement key={item.title} item={item} target={'_blank'} />))}
                      </MenuWrapper>
                    )}
                  </div>
                </Route>

                <Route path="/records">
                    <Link className={'cta-verbale'} to="agenda">{strings.drawer.agenda}</Link>
                    <Link className={'cta-verbale active'} to="agenda">{strings.drawer.records}</Link>
                  <MenuWrapper>
                    {staticMenu.map((item) => (<MenuElement key={item.title} item={item} />))}
                  </MenuWrapper>

                  {menuItem && (
                    <MenuWrapper>
                      {menuItem.map((item) => (<MenuElement key={item.title} item={item} target={'_blank'} />))}
                    </MenuWrapper>
                  )}
                </Route>
              </Switch>


            </div>
            <Switch>
              <Route path="/agenda">
                <CommitmentWrapper />
                <AgendaDetail />
              </Route>

              <Route path="/records">
                <RecordWrapper />
                <RecordActionSection />
              </Route>
            </Switch>

          </div>
          {inModalCreation && <RecordModal />}
        </Route>
      </Switch>
      {errorGlobal && <GlobalErrorModal />}
      {inModalDelete && <CommitmentDeleteModal />}
      {isOver46 && <Under46Modal />}
    </Fragment>
  );
};

export default AppWrapper;
