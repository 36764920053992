const strings = {

  AIGA: 'AIGA: Agenda Legale',

  logoClaim: 'Agenda Legale',

  signIn: {
    title: 'Accedi',
    actionForgotPassword: 'Recupera password',
    actionConfirm: 'Accedi',
    signUpQuestion: 'Non sei un utente AIGA?',
    actionSignUp: 'Registrati'
    //
  },
  forgotPassword: {
    headerTitle: 'Recupera password',
    text: 'Inserisci l\'email con cui hai effettuato la registrazione.',
    actionConfirm: 'Recupera Password',
    actionBackToLogin: 'Torna al login',
    popup: {
      title: 'E-Mail inviata',
      text: 'Controlla la tua mail. Ti abbiamo inviato un link per recuperare la tua password.',
      // action: 'Controlla e-mail',
      close: 'Chiudi'
    }
  },
  //
  // commons: {
  //   picker: {
  //     done: 'Fatto'
  //   }
  // },
  //
  // // Field Label
  fieldLabelFirstNameRegister: 'Quale è il tuo nome?',
  fieldLabelFirstNameSummary: 'Il tuo nome',
  fieldLabelLastNameRegister: 'Quale è il tuo cognome?',
  fieldLabelLastNameSummary: 'Il tuo cognome',
  fieldLabelEmail: 'E-mail',
  fieldLabelEmailRegister: 'Quale è la tua mail?',
  fieldLabelEmailSummary: 'La tua mail',
  fieldLabelPassword: 'Password',
  fieldLabelPasswordRegister: 'Scegli la tua password',
  fieldLabelPasswordSummary: 'La tua password',
  fieldLabelFiscalCode: 'Digita il tuo codice fiscale',
  fieldLabelFiscalCodeSummary: 'Il tuo Codice Fiscale è',
  fieldLabelBirthDate: 'Quando sei nato?',
  fieldLabelBirthDateSummary: 'Sei nato il',
  fieldLabelPhone: 'Quale è il tuo numero di telefono?',
  fieldLabelPhoneSummary: 'Il tuo numero di telefono è',
  fieldLabelCity: 'Il comune in cui operi è',
  fieldLabelSection: 'La sezione Aiga di appartenenza è',
  // fieldTitleTerms: 'Termini e condizioni',
  fieldLabelTerms: 'Ho preso visione dei Termini di Servizio',
  fieldLabelTermsSecondary: 'Termini di servizio',
  fieldLabelPrivacy: 'Ho preso visione della Privacy Policy',
  fieldLabelPrivacySecondary: 'Privacy Policy',
  fieldLabelGPDR: 'Ho preso visione del GDPR',
  fieldLabelGDPRSecondary: 'GDPR',

  // Field Placeholder
  fieldPlaceholderFirstName: 'Inserisci il tuo nome',
  fieldPlaceholderLastName: 'Inserisci il tuo cognome',
  fieldPlaceholderEmail: 'Inserisci la tua e-mail',
  // fieldPlaceHolderTimeStart: 'Ora di inzio',
  // fieldPlaceHolderTimeEnd: 'Ora di fine',
  fieldPlaceholderPassword: 'Inserisci la tua password',
  fieldPlaceholderFiscalCode: 'Inserisci il tuo codice fiscale',
  // fieldPlaceholderBirthDate: 'Inserisci la tua data di nascita',
  // fieldPlaceholderDate: 'Inserisci la data',
  // fieldPlaceholderType: 'Inserisci il tipo di evento',
  fieldPlaceholderPhone: 'Inserisci il tuo numero di telefono',
  fieldPlaceHolderCounterpart: 'Controparte',
  fieldPlaceHolderPart: 'Parte',
  // fieldPlaceHolderState: 'Inserisci lo stato',
  fieldPlaceHolderNotice: 'Nessuno',
  fieldPlaceHolderSearch: 'Cerca',
  fieldPlaceHolderAnnotation: 'Aggiungi delle note: Es. prima sezione',
  fieldPlaceHolderLink: 'Inserisci link',
  fieldPlaceHolderCode: 'Inserisci codice',
  //
  // Tooltip
  //
  tooltip: {
    passwordTitle: 'Requisiti Password',
    passwordText: 'La password deve essere lunga almeno 8 caratteri e contenere almeno un numero e una lettera',
    done: 'Segna come evasa',
    todo: 'Segna come non evasa',
    postpone: 'Rinvia',
    edit: 'Modifica',
    delete: 'Elimina'
  },
  //
  over: {
      title: 'Ops',
      description: 'Hai compiuto 46 anni, non puoi più utilizzare AIGA: Agenda Legale.',
      download: 'Scarica Collega',
      readOnly: 'Continua in modalità lettura',
  },
  //
  errors: {
    invalidField: 'Campo non valido',
    invalidEmptyPassword: 'La password non può essere vuota',
    invalidDate: 'Data non valida',
    invalidEmail: 'E-mail non valida',
    invalidPassword: 'Password non valida',
    invalidFiscalCode: 'Codice Fiscale non valida',
    isOverData: 'Devi avere meno di 46 anni per utilizzare AIGA: Agenda Legale.',
    invalidPhone: 'Numero di telefono non valido',
    invalidExpense: 'Importo non valido'
  },
  //
  onBoarding: {
    signIn: 'Accedi',
    signUp: 'Registrati',
    first: {
  //     pre: 'Benvenuto su',
      subtitle: 'L\'applicazione pensata per i giovani avvocati',
      text: 'L\'utilizzo dell\'applicazione è limitato ai soli iscritti all\'associazione AIGA.'
    },
    second: {
      pre: 'Tutto a portata di mano:',
      title: 'Appuntamenti, Adempimenti, Avvisi e Udienze.',
      text: 'AIGA: Agenda Legale ha pensato per te un calendario intelligente che ti consente di programmare con facilità e senza stress i tuoi impegni.'
    },
  //   third: {
  //     title: 'Creazione del verbale dâ€™udienza? Sarai veloce come un razzo.',
  //     text: 'Crea il verbale ovunque ti trovi, invita i tuoi colleghi a lavorare sullo stesso documento da qualsiasi dispositivo: telefono, tablet o computer.'
  //   }
  },

  signUp: {
    headerTitle: 'Registrazione',
    fifth: {
      cityLabel: 'Comune in cui operi',
      cityPlaceholder: 'Seleziona il Comune in cui operi',
      sectionLabel: 'Sezione Aiga di appartenenza',
      sectionPlaceholder: 'Seleziona la sezione Aiga'
    },
    //   actionNext: 'Avanti',
    //   terms: {
    //     title: 'Leggi e accetta i termini e le condizioni per procedere.'
    //   },
    //   done: {
    //     title: 'Fantastico!',
    //     description: 'Il tuo account Ã¨ stato creato correttamente. Ora puoi accedere ad AIGA PERSONAL PLANNING ed utilizzare le sue innovative funzionalitÃ .',
    //     action: 'Accedi ad AIGA',
    //   },
    //   under46: {
    //     popup: {
    //       title: 'Ops',
    //       description: 'Hai compiuto 46 anni, non puoi piÃ¹ utilizzare AIGA Personal Planning. Per te ora câ€™Ã¨ Collega!',
    //       action: 'Scarica Collega',
    //       continue: 'Continua in modalitÃ  lettura'
    //     }
    //   },
    //
    //   webView: {
    //     action: 'Accetto'
    //   },

    summary: {
      headerTitle: 'Riepilogo',
      headerDescription: 'Controlla i dati che hai inserito e procedi con la creazione del tuo account!',
      // actionEdit: 'Modifica',
    }
  },
  //
  //
  //
  drawer: {
    agenda: 'Agenda Digitale',
    records: 'Verbale d\'Udienza',
    share: 'Condividi app con i tuoi colleghi',
    support: 'Invia Segnalazioni',
    aiga: 'Scopri AIGA',
    rate: 'Valuta App'
  },
  //
  profile: {
    headerTitle: 'Profilo',
    passwordHidden: '*********************',
    actionEdit: 'Modifica',
  //
    edit: {
      // actionCancel: 'Annulla',
      actionSave: 'Salva',
      // actionChangePassword: 'Cambia la password'
    },
  },

  agenda: {
    more: 'carica altri eventi',
    //   headerTitle: 'Agenda',
    add: {
      headerTitle: 'Crea Impegno',
      notice: 'Avviso',
      addNotice: 'Aggiungi un Avviso',
      judge: 'Giudice',
      addJudje: 'Aggiungi un Giudice',
      judicialOffice: 'Ufficio Giudiziario',
      addJudicialOffice: 'Aggiungi Ufficio Giudiziario',
      nrg: 'NRG',
      addNrg: 'Aggiungi il codice NRG',
      addAnnotation: 'Vuoi aggiungere delle note?',
      //     annotation: 'Note',
      type: 'Che tipo di evento vuoi creare?',
      activity: 'Quali attività  devi svolgere?',
      allDay: 'Tutto il giorno',
      date: 'In quale data?',
      start: 'Ora Inizio',
      end: 'Ora Fine',
      part: 'Definisci una Parte',
      counterpart: 'Definisci una Controparte',
      expense1Action: 'Aggiungi spese esclusa IVA',
      expense1Label: 'Spese esclusa IVA',
      expense1Placeholder: 'Inserisci le spese esclusa IVA',

      expense2Action: 'Aggiungi spese imponibili',
      expense2Label: 'Spese Imponibili',
      expense2Placeholder: 'Inserisci le spese imponibili',
      //
      expense3Action: 'Aggiungi compenso',
      expense3Label: 'Compenso',
      expense3Placeholder: 'Inserisci compenso',
    },
    //
    edit: {
      headerTitle: 'Modifica Impegno',
      action: {
        modify: 'Modifica'
      },
      postpone: {
        headerTitle: 'Rinvia',
        action: 'Rinvia Udienza'
      }
    },
    event: {
      items: {
        activity: 'Attività  da svolgere',
        notice: 'Ricordami',
        nrg: 'NRG',
        expence1: 'Spese Imponibili',
        expence2: 'Spese Escluse IVA',
        expence3: 'Spese Compenso',
        notes: 'Note',
        notesPlaceholder: 'Nessuna nota inserita',
        judge: 'Giudice',
        office: 'Ufficio Giudiziario'
      }
    }
  },
  //
  records: {
    headerTitle: 'Verbale di Udienza',
      link: 'Copia link',
    create: 'Creati da me',
    share: 'Condivisi con me',
    //
    actionSheet: {
      //     title: 'Vuoi creare un verbale o vuoi aggiungerti?',
      options: {
        create: 'Crea verbale',
        //       add: 'Aggiungi verbale',
        //       cancel: 'Annulla'
      }
    },
    //
    add: {
      headerTitle: 'Crea Verbale',
          share: 'Condivisione',
          link: 'Link di condivisione',
          accessCode: 'Codice di accesso',
          go: 'Vai al verbale'
    },
    right: {
      empty: {
        question: 'Vuoi creare un nuovo verbale di udienza?',
        copy: 'In questo momento non ci sono verbale attivi.'
      },
      shared: {
        copy: 'Inserisci il link e il codice che ti sono stati inviati in basso per partecipare alla scrittura del verbale di udienza.',
        link: 'Link',
        code: 'Codice',
        action: {
          go: 'Scrivi nel verbale'
        }
      }
    },
    modal: {
      title: 'Attenzione!',
      copy: 'Non puoi modificare nulla dopo aver confermato.',
      action: {
        confirm: 'Conferma',
        cancel: 'Annulla'
      }
    }
  }
};

export default strings;
