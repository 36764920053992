import moment from 'moment';
import Utility from '../../utils/utils';
import {Profile} from './Profile';
import City from './City';
import Office from './Office';

export class PendingUser {
  first_name: string = '';
  last_name: string = '';

  email: string = '';
  password: string = '';
  password1: string = '';
  password2: string = '';
  terms: boolean = false;
  privacy: boolean = false;
  gdpr: boolean = false;

  birth_date: string = '';
  birth_day: string = '';
  birth_month: string = '-1';
  birth_year: string = '';

  fiscal_code: string = '';

  phone: string = '';

  profile?: Profile;

  constructor(parameter?: any) {
    if (parameter) {
      const {profile, city, aiga_section, ...object} = parameter;
      Object.assign(this, object);
      if (profile) {
        this.profile = new Profile(profile);
      }
      if (city) {
        this.city = city;
      }
      if (aiga_section) {
        this.aiga_section = aiga_section;
      }
    } else {
      Object.assign(this, parameter);
    }

    this.birth_date = this.checkDateValidity() ? `${this.birth_year}-${+this.birth_month + 1}-${this.birth_day}` : this.birth_date;
    this.password1 = this.password;
    this.password2 = this.password;
  }

  get city(): City | undefined {
    return this.profile && this.profile.city;
  }

  set city(obj: City | undefined) {
    this.profile = new Profile({
      ...this.profile,
      city: obj
    });
    if (!obj) {
      this.profile.city = undefined;
    }
  }

  get aiga_section(): Office | undefined {
    return this.profile && this.profile.aiga_section;
  }

  set aiga_section(obj: Office | undefined) {
    this.profile = new Profile({
      ...this.profile,
      aiga_section: obj
    });

    if (!obj) {
      this.profile.aiga_section = undefined;
    }
  }

  checkDateField() {
    return !!this.birth_year && !!this.birth_day && this.birth_month !== '-1';
  }

  checkDateValidity() {
    return this.checkDateField() &&
      moment([this.birth_year, this.birth_month, this.birth_day]).isValid();
  }

  validateName() {
    return this.first_name.length > 0 && this.last_name.length > 0;
  }

  validateFlag() {
    return this.privacy && this.terms;
  }

  checkSignUpValidity() {
    return this.checkDateValidity() && this.validateName() && this.validateFlag() &&
      Utility.validateEmail(this.email) && Utility.validatePassword(this.password) &&
      Utility.validateFiscalCode(this.fiscal_code) && Utility.validatePhone(this.phone);
  }

}
