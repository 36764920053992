import React, {FormEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IUserReducer, updatePendingUser} from '../../store/reducers/UserReducer';
import strings from '../../values/strings';
import Input from '../../components/common/Input';
import {FormFields} from '../../values/values';
import {IStepFirst} from './interface/SignupInterface';


const StepFirst: React.FC<IStepFirst> = ({first_name, last_name, next}) => {

  const pendingSignUpUser = useSelector((state: IUserReducer) => state.user.pendingSignUpUser);
  const dispatch = useDispatch();

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  return (
    <form onSubmit={preventSubmit(next)}>
      <h1>{strings.signUp.headerTitle}</h1>
      <Input
        label={strings.fieldLabelFirstNameRegister}
        placeholder={strings.fieldPlaceholderFirstName}
        value={pendingSignUpUser!.first_name}
        autoFocus={true}
        onChange={
          (event?: React.ChangeEvent<HTMLInputElement>) => {
            if (event) {
              dispatch(updatePendingUser(FormFields.first_name, event.target.value));
              if (event.target.value.length > 0) {
                first_name.setValidity(true);
              }
            }
          }
        }
        onBlur={
          (event?: React.FocusEvent<HTMLInputElement>) => {
            if (event) {
              first_name.setValidity(pendingSignUpUser!.first_name.length > 0);
            }
          }
        }
        error={!first_name.valid ? strings.fieldPlaceholderFirstName : undefined}
      />
      <Input
        label={strings.fieldLabelLastNameRegister}
        placeholder={strings.fieldPlaceholderLastName}
        value={pendingSignUpUser!.last_name}
        onChange={
          (event?: React.ChangeEvent<HTMLInputElement>) => {
            if (event) {
              dispatch(updatePendingUser(FormFields.last_name, event.target.value));
              if (event.target.value.length > 0) {
                last_name.setValidity(true);
              }
            }
          }
        }
        onBlur={
          (event?: React.FocusEvent<HTMLInputElement>) => {
            if (event) {
              last_name.setValidity(pendingSignUpUser!.last_name.length > 0);
            }
          }
        }
        error={!last_name.valid ? strings.fieldPlaceholderLastName : undefined}
      />
      <button type={'submit'} className={'button-placeholder-submit'} tabIndex={-1}/>
    </form>
  );
};

export default StepFirst;
