import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAgendaReducer,
  setSearchedEvents,
  setSearchVisibility,
  toggleIsVisibleFilters,
  setSearchPage,
} from '../../store/reducers/AgendaReducer';
import CommitmentFilters from './CommitmentFilters';
import { IAppReducer, newCommitment } from '../../store/reducers/AppReducer';

import { add, close } from '../../image/icons';
import strings from '../../values/strings';
import Input from '../common/Input';
import OutsideClickHandler from 'react-outside-click-handler';
import EventsService from '../../services/EventService';
import { IUserReducer } from '../../store/reducers/UserReducer';
import _ from 'lodash';

interface ICommitmentSearch {
  callback: (query: string) => void;
}

const CommitmentSearch: React.FC<ICommitmentSearch> = ({ callback }) => {

  const dispatch = useDispatch();

  const [query, setQuery] = useState<string>('');
  const [timeout, _setTimeout] = useState()

  const dateEvents = useSelector((state: IAgendaReducer) => state.agenda.events);
  const searchPage = useSelector((state: IAgendaReducer) => state.agenda.searchPage);

  const checkIfIsEmptySearch = useCallback(() => {
    if (query.length === 0) {
      dispatch(setSearchedEvents(undefined, true));
      dispatch(setSearchVisibility(false));
    }
  }, [query]);

  useEffect(() => {
    handleQuery()
  }, [query]);


  let handleQuery = async () => {
    if (!_.isEmpty(query)) {

      dispatch(setSearchedEvents(undefined, true));

      if (timeout) clearTimeout(timeout)
      _setTimeout(setTimeout(async () => {
        callback(query);
      }, 300))
    }
  }


  // Perform request when page changes
  useEffect(() => {
    console.log('Page is: ' + searchPage)
    if (!_.isEmpty(query)) callback(query);
  }, [searchPage]);

  return (
    <OutsideClickHandler onOutsideClick={checkIfIsEmptySearch}>
      <form>
        <Input
          value={query}
          onChange={(e?: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e!.target.value);
          }}
          placeholder={strings.fieldPlaceHolderSearch}
          autoFocus={true}
          style={{
            paddingRight: 35
          }} />
        <div className={'close'} style={{
          background: `url(${close}) center center no-repeat`
        }} onClick={() => setQuery('')} />
      </form>
    </OutsideClickHandler>
  );
};

const CommitmentHeader: React.FC = () => {

  const isVisibleFilters = useSelector((state: IAgendaReducer) => state.agenda.isVisibleFilters);
  const isVisibleSearch = useSelector((state: IAgendaReducer) => state.agenda.isVisibleSearch);
  const endReached = useSelector((state: IAgendaReducer) => state.agenda.endReached);
  const searchPage = useSelector((state: IAgendaReducer) => state.agenda.searchPage);

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);

  const dispatch = useDispatch();

  const [searchResponse, , searchRequest] = EventsService.useSearchEventsAPI();


  useEffect(() => {
    setTimeout(async () => {
      if (endReached) {
        if (_.isEmpty(searchResponse)) return
        await dispatch(setSearchPage(searchPage + 1))
      }
    }, 0)
  }, [endReached])


  return (
    <div className={'commitment__header'} style={{ position: 'relative' }}>
      <div className={'flex-row'}>
        <h1 style={{ position: 'relative' }}>I tuoi impegni
          {isLoading &&
            <span className={'button-loading'} style={{ width: 30, height: 30, left: 'calc(100% + 20px)' }} />}
        </h1>
        <div className={classNames('commitment__header__search', { visible: isVisibleSearch })}>
          {isVisibleSearch && <CommitmentSearch callback={searchRequest} />}
        </div>
        <div className={'commitment__header__cta'}>
          <button className={'button-search'} onClick={() => dispatch(setSearchVisibility(true))} />
          <button className={'button-filter'} onClick={() => dispatch(toggleIsVisibleFilters())} />
        </div>
      </div>
      {(currentUser && currentUser.isUder46()) && (
        <div className={'flex-row'}>
          <button className={'button-secondary icon'} onClick={() => dispatch(newCommitment())}>
            <span className={'icon'}><img src={add} alt="add" /></span>
            <span className={'txt'}>{strings.agenda.add.headerTitle}</span>
          </button>
        </div>
      )}
      {isVisibleFilters && <CommitmentFilters />}
    </div>
  );
};


export default CommitmentHeader;

