import React from 'react';
import ReactDOM from 'react-dom';
// tslint:disable-next-line:no-submodule-imports
import './main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import OnboardingMob from './page/OnboardingMob';
import * as Sentry from '@sentry/browser';

Sentry.init({dsn: "https://b133567f25944cd587f946feae4a0cf6@sentry.io/1855102"});

ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<OnboardingMob />, document.getElementById('mobile'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
