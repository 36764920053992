import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {IUserReducer, updatePendingUser} from '../../store/reducers/UserReducer';
import Utility from '../../utils/utils';
import {FormFields} from '../../values/values';
import strings from '../../values/strings';
import Input from '../../components/common/Input';
import {IStepThird} from './interface/SignupInterface';

const StepThird: React.FC<IStepThird> = ({fiscal_code, birth_date, next}) => {

  const pendingSignUpUser = useSelector((state: IUserReducer) => state.user.pendingSignUpUser);
  const dispatch = useDispatch();

  const [birthIsFocused, setBirthIsFocused] = useState([0, 0, 0]);
  const [canValidateBirth, setCanValidateBirth] = useState(false);

  useEffect(() => {
      if (birthIsFocused.indexOf(0) === -1) {
        setCanValidateBirth(true);
      }
    }, [birthIsFocused]
  );

  useEffect(() => {
    if (pendingSignUpUser && pendingSignUpUser.checkDateField()) {
      const isValid = pendingSignUpUser.checkDateValidity();
      birth_date.setValidity(isValid);
    }
  }, [pendingSignUpUser]);

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  return (
    <form onSubmit={preventSubmit(next)}>
      <h1>{strings.signUp.headerTitle}</h1>
      <Input
        name={'fiscal-code'}
        label={strings.fieldLabelFiscalCode}
        placeholder={strings.fieldPlaceholderFiscalCode}
        autoFocus={true}
        value={pendingSignUpUser!.fiscal_code}
        onChange={
          (event?: React.ChangeEvent<HTMLInputElement>) => {
            if (event) {
              dispatch(updatePendingUser(FormFields.fiscal_code, event.target.value.toUpperCase()));
              if (Utility.validateFiscalCode(event.target.value)) {
                fiscal_code.setValidity(true);
              }
            }
          }
        }
        onBlur={
          (event?: React.FocusEvent<HTMLInputElement>) => {
            if (event) {
              fiscal_code.setValidity(Utility.validateFiscalCode(event.target.value));
            }
          }
        }
        error={!fiscal_code.valid ? strings.errors.invalidFiscalCode : undefined}
      />
      <label>{strings.fieldLabelBirthDate}</label>
      <div className={'date-container'}>
        <input type="text" placeholder={'GG'}
               value={pendingSignUpUser!.birth_day}
               onChange={
                 (event?: React.ChangeEvent<HTMLInputElement>) => {
                   if (event) {
                     if (!event.target.value) {
                       dispatch(updatePendingUser(FormFields.birth_day, ''));
                     } else if (!isNaN(Number(event.target.value))) {
                       const value = +event.target.value;
                       if (value < 1) {
                         dispatch(updatePendingUser(FormFields.birth_day, '1'));
                       } else if (value > 31) {
                         dispatch(updatePendingUser(FormFields.birth_day, '31'));
                       } else {
                         dispatch(updatePendingUser(FormFields.birth_day, event.target.value));
                       }
                     }
                   }
                 }
               }
               onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                 event.target.select();
                 const birthIsFocusedNew = [...birthIsFocused];
                 birthIsFocusedNew[0] = 1;
                 setBirthIsFocused(birthIsFocusedNew);
               }}
        />
        <div className={'select-container'}>
          <span />
          <select
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              dispatch(updatePendingUser(FormFields.birth_month, event.target.value));
            }}
            value={pendingSignUpUser!.birth_month}
            onFocus={() => {
              const birthIsFocusedNew = [...birthIsFocused];
              birthIsFocusedNew[1] = 1;
              setBirthIsFocused(birthIsFocusedNew);
            }}
          >
            <option disabled={true} value={'-1'}> -- mese --</option>
            <option value={'0'}>Gennaio</option>
            <option value={'1'}>Febbraio</option>
            <option value={'2'}>Marzo</option>
            <option value={'3'}>Aprile</option>
            <option value={'4'}>Maggio</option>
            <option value={'5'}>Giugno</option>
            <option value={'6'}>Luglio</option>
            <option value={'7'}>Agosto</option>
            <option value={'8'}>Settembre</option>
            <option value={'9'}>Ottobre</option>
            <option value={'10'}>Novembre</option>
            <option value={'11'}>Dicembre</option>
          </select>
        </div>
        <input type="text" placeholder={'AAAA'}
               value={pendingSignUpUser!.birth_year}
               onChange={
                 (event?: React.ChangeEvent<HTMLInputElement>) => {
                   if (event) {
                     if (!event.target.value) {
                       dispatch(updatePendingUser(FormFields.birth_year, ''));
                     } else if (!isNaN(Number(event.target.value))) {
                       dispatch(updatePendingUser(FormFields.birth_year, event.target.value));
                     }
                   }
                 }
               }
               onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                 event.target.select();

                 const birthIsFocusedNew = [...birthIsFocused];
                 birthIsFocusedNew[2] = 1;
                 setBirthIsFocused(birthIsFocusedNew);
               }}
        />
      </div>
      {(!birth_date.valid && canValidateBirth) && <p className={'error'}>{strings.errors.invalidDate}</p>}
      {/*{(!isUnder46 && birth_date.valid && canValidateBirth) && <p className={'error'}>{strings.errors.isOverData}</p>}*/}

      <button type={'submit'} className={'button-placeholder-submit'} tabIndex={-1}/>
    </form>
  );
};

export default StepThird;
