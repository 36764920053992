import React, {Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EventsService from '../../services/EventService';
import {IAgendaReducer, showDeleteModal} from '../../store/reducers/AgendaReducer';

const CommitmentDeleteModal: React.FC = () => {

  const [, , deleteRequest] = EventsService.useDeleteEventAPI();
  const currentEvent = useSelector((state: IAgendaReducer) => state.agenda.event);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className={'modal opened'}>
        <div className={'modal__content'}>
          <p className={'title'}>Elimina impegno</p>
          <p className={'copy'}>Confermi di voler eliminare l'evento</p>

          <button className={'button-primary'} onClick={async () => {
            if (currentEvent) {
              await deleteRequest(currentEvent);
            }
            dispatch(showDeleteModal(false));
          }}>
            <span>Conferma</span>
          </button>
          <button className={'button-main'} style={{paddingLeft: 21, paddingRight: 21}} onClick={() => {
            dispatch(showDeleteModal(false));
          }}>
            <span>Annulla</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default CommitmentDeleteModal;
