import React, { useCallback, useEffect } from 'react';
import RecordRightCreate from './RecordRightCreate';
import RecordDetail from './RecordDetail';
import RecordRightShared from './RecordRightShared';
import { useSelector, useDispatch } from 'react-redux';
import { IRecordsReducer, selectRecord } from '../../store/reducers/RecordReducer';
import { IUserReducer } from '../../store/reducers/UserReducer';
import classNames from 'classnames';
import { tabThirdClose, tabThirdOpen, IAppReducer } from '../../store/reducers/AppReducer';
import closeRight from '../../image/icons/close-right.svg';
import { isTablet } from 'react-device-detect';

const RecordActionSection: React.FC = () => {

  const opened = useSelector((state: IAppReducer) => state.app.isOpenTabThird);

  const currentRecord = useSelector((state: IRecordsReducer) => state.record.record);
  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const mineOrShared = useSelector((state: IRecordsReducer) => state.record.mineOrShared);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isTablet) {
      dispatch(tabThirdClose());
    } else {
      dispatch(tabThirdOpen());
    }
  }, [])

  const onToggleArrowClick = useCallback(() => {
    if (opened) {
      dispatch(tabThirdClose());
      dispatch(selectRecord())
    } else {
      dispatch(tabThirdOpen());
    }
  }, [opened]);

  return (
    <div className={classNames('verbalRight', { closed: !opened })}>


      {(!currentRecord && mineOrShared && currentUser && currentUser.isUder46()) && (
        <div >
          <div className={'close-right'} onClick={() => onToggleArrowClick()}>
            <img src={closeRight} />
          </div>
          <RecordRightCreate />
        </div>
      )}
      {(!currentRecord && !mineOrShared) && (
        <div >
          <div className={'close-right'} onClick={() => onToggleArrowClick()}>
            <img src={closeRight} />
          </div>
          <RecordRightShared />
        </div>
      )}
      {currentRecord && (
        <div >
          <div className={'close-right'} onClick={() => onToggleArrowClick()}>
            <img src={closeRight} />
          </div>
          <RecordDetail record={currentRecord} />
        </div>
      )}



    </div>
  );
};

export default RecordActionSection;
