import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import AppReducer from './AppReducer';
import RecordsReducer from './RecordReducer';
import AgendaReducer from './AgendaReducer';
import { notificationReducer } from './NotificationsReducer';


const reducers = combineReducers({
  user: UserReducer,
  app: AppReducer,
  record: RecordsReducer,
  agenda: AgendaReducer,
  notificationsReducer: notificationReducer
});

export default reducers;
