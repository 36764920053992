import React, { FormEvent, Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../image/logo-aiga.svg';
import login from '../image/login__image.svg';
import UserService from '../services/UserServices';
import strings from '../values/strings';
import Utility from '../utils/utils';
import Input from '../components/common/Input';
import classNames from 'classnames';
import { ISignUpError } from './signup/interface/SignupInterface';
import { FormFields } from '../values/values';
import { useSelector } from 'react-redux';
import { IAppReducer } from '../store/reducers/AppReducer';

const SignIn: React.FC = () => {

  const [email, setEmail] = useState();
  const [password, setPassword] = useState('');
  const [, error, request] = UserService.useLogin();

  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);

  //  ACTIONS
  const onLogin = () => {
    if (!isLoading && (Utility.validateEmail(email) && (password.length > 0))) {
      request(email, password);
    }
  };

  const unHide = () => {
    setUnHidePassword(!unHidePassword);
  };

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  useEffect(() => {
    if (error) {
      setServerError({ ...error });
      Object.keys(error).forEach((key) => {
        switch (key) {
          case FormFields.email:
            setIsValidEmail(false);
            break;
          case FormFields.password:
            setIsValidPassword(false);
            break;
          case 'non_field_errors':
            setIsValidRequest(false);
            break;
        }
      });
    }
  }, [error]);

  const [isValidEMail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidRequest, setIsValidRequest] = useState(true);
  const [unHidePassword, setUnHidePassword] = useState(false);

  const initError: ISignUpError = {};
  const [serverError, setServerError] = useState(initError);

  const enableButton = Utility.validateEmail(email) && (password.length > 0);

  return (
    <Fragment>
      <div className={'header-login'}>
        <img className={'logo'} src={logo} alt="Logo aiga" />
      </div>
      <div className={'wrapper-login'}>
        <div className={'login'}>
          <div className={'login__form'}>
            <h1>{strings.signIn.title}</h1>
            <form onSubmit={preventSubmit(onLogin)}>
              <Input
                label={strings.fieldLabelEmail}
                type="email"
                name="email"
                placeholder={strings.fieldPlaceholderEmail}
                autoFocus={true}
                readOnly={isLoading}
                onChange={
                  (event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      setEmail(event.target.value);
                      if (Utility.validateEmail(event.target.value)) {
                        setIsValidEmail(true);
                        setIsValidRequest(true);
                        const { email: _, non_field_errors: __, ...rest } = serverError;
                        setServerError(rest);
                      }
                    }
                  }
                }
                onBlur={
                  (event?: React.FocusEvent<HTMLInputElement>) => {
                    if (event) {
                      setIsValidEmail(Utility.validateEmail(event.target.value));
                    }
                  }
                }
                error={!isValidEMail ? (serverError.email ? serverError.email : strings.errors.invalidEmail) : undefined}
              />
              <div className={'flex-row'}>
                <label>{strings.fieldLabelPassword}</label>
              </div>
              <div className={'password-container'}>
                <span className={classNames('unhide', { hide: unHidePassword })} onClick={unHide} />
                <Input
                  type={!unHidePassword ? 'password' : 'text'}
                  name="password"
                  placeholder={strings.fieldPlaceholderPassword}
                  onChange={
                    (event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) {
                        setPassword(event.target.value);
                        if (event.target.value.length > 0) {
                          setIsValidPassword(true);
                          setIsValidRequest(true);
                          const { password: _, non_field_errors: __, ...rest } = serverError;
                          setServerError(rest);
                        }
                      }
                    }
                  }
                  onBlur={
                    (event?: React.FocusEvent<HTMLInputElement>) => {
                      if (event) {
                        setIsValidPassword(event.target.value.length > 0);
                      }
                    }
                  }
                  error={!isValidPassword ? (serverError.password ? serverError.password : strings.errors.invalidEmptyPassword) : undefined}
                />
              </div>

              <Link className={'recover'} to={'forgot'}>{strings.signIn.actionForgotPassword}</Link>

              {!isValidRequest && <p className={'error'}>{serverError.non_field_errors}</p>}

              <button
                className={classNames('button-primary', { disabled: !enableButton })}
                disabled={!enableButton}
                onClick={onLogin}
              >
                <span className={classNames({ unvisible: isLoading })}>{strings.signIn.actionConfirm}</span>
                {isLoading && <span className={'button-loading'} />}
              </button>

              <p>{strings.signIn.signUpQuestion} <Link to="signup">{strings.signIn.actionSignUp}</Link></p>
            </form>
          </div>
          <div className={'image__form'}>
            <img src={login} alt="Logo aiga" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignIn;
