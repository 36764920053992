import React, {Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {setShowUnder46} from '../../store/reducers/UserReducer';
import strings from '../../values/strings';
import {ExternalLinks} from '../../values/values';

const Under46Modal: React.FC = () => {

  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className={'modal opened'}>
        <div className={'modal__content'}>
          <p className={'title'}>{strings.over.title}</p>
          <p className={'copy'}>{strings.over.description}</p>

          <form onSubmit={() => {
            setTimeout(() => {
              dispatch(setShowUnder46(false));
            }, 50);
            return true;
          }} target={'_blank'} method={'get'} action={ExternalLinks.CollegaWebsite}>
            {/* <button className={'button-primary'} type={'submit'}>
              <span>{strings.over.download}</span>
            </button> */}
          </form>
          <button className={'button-main'} style={{paddingLeft:21, paddingRight:21}} onClick={() => {
            dispatch(setShowUnder46(false));
          }}>
            <span>{strings.over.readOnly}</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default Under46Modal;
