import moment from 'moment';
import {DateFormat} from '../values/values';
import SelectOption from '../store/types/SelectionOption';


export interface IHash {
  [details: string]: string;
}

export default class Utility {

  static validateEmail = (text?: string): boolean => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!text) {
      return false;
    }

    return regex.test(text);
  };

  static validatePassword = (text?: string): boolean => {
    const regex = /^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/;

    if (!text) {
      return false;
    }
    return regex.test(text);
  };

  static validateFiscalCode = (text?: string): boolean => {
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
    const regex = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/;

    if (!text) {
      return false;
    }
    return regex.test(text);
  };

  static isUnder46 = (date: string) => {
    const age = moment().diff(moment(date, DateFormat.server), 'years');
    return age < 46;
  };

  static validatePhone = (text?: string): boolean => {
    const regex = /^\+?[0-9]{3}-?([0-9]{7}|[0-9]-[0-9]{2}-[0-9]{2}-[0-9]{2}|[0-9]{3}-[0-9]{2}-[0-9]-[0-9])$/;

    if (!text) {
      return false;
    }
    return regex.test(text);
  }

  static selectOptions = (values: any, first?: number): SelectOption[] => {
    return Object.keys(values).map((key) => new SelectOption(String(key), values[key])).slice(0, first);
  };

  static sliced(value: number, digits: number) {
    const digitString = Array(digits).join('0');
    return (digitString + value).slice(-digits);
  }

}
