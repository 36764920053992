export default class Storage {

  static AUTH_TOKEN = 'AUTH_TOKEN';

  static set = async (key: string, object: any) => {
    try {
      await localStorage.setItem(key, JSON.stringify(object));
    } catch (e) {
      debugger;
    }
  };

  static get = async (key: string) => {
    try {
      const value = await localStorage.getItem(key);
      if (value !== null) {
        return JSON.parse(value);
      }
    } catch (e) {
      debugger;
    }
  };

  static clear = async () => {
    localStorage.clear();
  };

}
