import React, {useEffect, useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import {useDispatch, useSelector} from 'react-redux';
import {IAgendaReducer, selectDayOnCalendar, setCurrentDay, setSearchedEvents} from '../../store/reducers/AgendaReducer';
import moment from 'moment';
import {DateFormat} from '../../values/values';
import {IAppReducer} from '../../store/reducers/AppReducer';

interface ICustomHeader {
  date: Date;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;

  changeYear(year: number): void;

  changeMonth(month: number): void;

  decreaseMonth(): void;

  increaseMonth(): void;
}

const CustomHeader: React.FC<ICustomHeader> = (props) => {
  const {date, prevMonthButtonDisabled, nextMonthButtonDisabled, changeYear, changeMonth, decreaseMonth, increaseMonth} = props;

  const currentDate = useSelector((state: IAgendaReducer) => state.agenda.day);
  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading)

  const dispatch = useDispatch();

  return (
    <>
      <button className={'react-datepicker__navigation react-datepicker__navigation--next'} style={isLoading?{opacity:'0.5'}: undefined}
              onClick={() => {
                if (!isLoading) {
                  increaseMonth();
                  dispatch(setCurrentDay(moment(date).add(1, 'month').startOf('month').format(DateFormat.server)));
                }
              }}>Next Month
      </button>
      <button className={'react-datepicker__navigation react-datepicker__navigation--previous'} style={isLoading?{opacity:'0.5'}: undefined}
              onClick={() => {
                if (!isLoading) {
                  decreaseMonth();
                  dispatch(setCurrentDay(moment(date).add(-1, 'month').startOf('month').format(DateFormat.server)));
                }
              }}>Prev Month
      </button>
      <div className={'react-datepicker__current-month'}>
        {moment(date).format('MMMM YYYY')}
      </div>
    </>);
};

const CustomHEEE = (props: ICustomHeader) => {
  return <CustomHeader {...props} />;
};

const Calendar: React.FC = () => {

  const [startDate, setStartDate] = useState(new Date());

  const currentDate = useSelector((state: IAgendaReducer) => state.agenda.day);
  const topDate = useSelector((state: IAgendaReducer) => state.agenda.onTopDay);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentDate) {
      setStartDate(new Date(currentDate));
    }
  }, [currentDate]);
  useEffect(() => {
    if (topDate) {
      setStartDate(new Date(topDate));
    }
  }, [topDate]);

  return (
    <ReactDatePicker
      selected={startDate}
      onChange={(date: Date) => {
        dispatch(selectDayOnCalendar(moment(date).format(DateFormat.server)));
        dispatch(setSearchedEvents(undefined, true));
      }}
      inline={true}
      locale="it"
      renderCustomHeader={CustomHEEE}
    />
  );
};

export default Calendar;
