export default class City {
  id?: number;
  name?: string;
  zip?: string;

  constructor(parameters: string | Partial<City>) {
    if (parameters instanceof String) {
      this.name = String(parameters);
    } else {
      Object.assign(this, parameters);
    }
  }
}
