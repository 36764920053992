import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Network, {NetworkMethod} from '../network/Network';
import Event from '../store/types/Event';
import Office from '../store/types/Office';
import SelectOption from '../store/types/SelectionOption';
import moment from 'moment';
import {DateFormat, PartsType, EventStatus} from '../values/values';
import {
  setEvents,
  deleteEvent,
  addEvent,
  updateEvent,
  setCurrentEvent,
  setSearchedEvents,
  IAgendaReducer
} from '../store/reducers/AgendaReducer';
import AppReducer, {
  IAppReducer,
  selectEvent,
  setError500,
  setIsLoading,
  tabThirdClose
} from '../store/reducers/AppReducer';

class EventsService {

  static useEventsAPI = (): [Map<string, Event[]>, any, (dateMin: Date, dateMax: Date) => void] => {
    const [response, setResponse] = useState();
    const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (dateMin: Date, dateMax: Date) => {
      if (!isLoading) {
        dispatch(setIsLoading(true));

        const method = NetworkMethod.get;
        const endpoint = 'events/calendar/';
        const params = {
          date_min: moment(dateMin).format(DateFormat.server),
          date_max: moment(dateMax).format(DateFormat.server)
        };

        const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
        dispatch(setIsLoading(false));

        setError(networkResponse.error);

        if (networkResponse.data) {
          setResponse(networkResponse.data);
          dispatch(setEvents(networkResponse.data));
        }
        if (networkResponse.error500) {
          dispatch(setError500(true));
        }
      }

    };

    return [response, error, request];
  };


  static useSearchEventsAPI = (): [Event[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const page = useSelector((state: IAgendaReducer) => state.agenda.searchPage);

    
    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = 'events/?page=' + page
      const params = {
        search: query
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        dispatch(setSearchedEvents(networkResponse.data));
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };


  static useEventAPI = (): [Event, any, (eventID: number) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (eventID: number) => {

      const method = NetworkMethod.get;
      const endpoint = 'events/' + eventID + '/';

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, null);
      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

  static useCreateEventAPI = (): [Event, any, (event: Event) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (event: Event) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'events/';
      const data = JSON.stringify(event);

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
        dispatch(addEvent(networkResponse.data));
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };


  static useUpdateEventAPI = (): [Event, any, (oldEvent: Event, event: Event) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (oldEvent: Event, event: Event) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.put;
      const endpoint = 'events/' + event.id + '/';
      const data = event

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
        dispatch(updateEvent(oldEvent, networkResponse.data));
        dispatch(selectEvent());
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };


  static useUpdateEventStatusAPI = (): [Event, any, (event: Event, status: EventStatus) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (event: Event, status: EventStatus) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.get;
      const endpoint = 'events/' + event.id + '/' + status.toLowerCase() + '/';

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        dispatch(updateEvent(event, new Event(networkResponse.data)));
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };


  static useDeleteEventAPI = (): [any, any, (event: Event) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (event: Event) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.delete;
      const endpoint = 'events/' + event.id + '/';

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data !== undefined) {
        dispatch(deleteEvent(event));
        setResponse(networkResponse.data);
        dispatch(tabThirdClose());
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

  static usePostponeEvent = (): [Event, Event, any, (oldEvent: Event, event: Event) => void] => { // Chained requests
    const dispatch = useDispatch();
    const [error,] = useState();
    const [createResponse, , createRequest] = EventsService.useCreateEventAPI();
    const [updateStatusResponse, , updateStatusRequest] = EventsService.useUpdateEventStatusAPI();
    const request = async (oldEvent: Event, event: Event) => {

      await createRequest(event);
      await updateStatusRequest(oldEvent, EventStatus.POSTPONED);
      dispatch(setCurrentEvent(event));
      dispatch(selectEvent());
    };

    return [createResponse, updateStatusResponse, error, request];
  };

  static usePartsAPI = (): [SelectOption[], any, (partsType: PartsType) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (partsType: PartsType) => {

      const method = NetworkMethod.get;
      const endpoint = `autocomplete/${partsType}/`;

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, null);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const options = (networkResponse.data as any[]).map((part) => new SelectOption(part.name, part.name));
        setResponse(options);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

  static useSearchPartsAPI = (partsType: PartsType): [SelectOption[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = `autocomplete/${partsType}/`;
      const params = {
        search: query
      };
      // if (query.length < 3 || _.isEmpty(query)) return // TODO:
      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const options = (networkResponse.data as any[]).map((part) => new SelectOption(part.name, part.name));
        setResponse(options);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

  static useSearchActivitiesAPI = (): [SelectOption[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = `autocomplete/activities/`;
      const params = {
        search: query
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const options = (networkResponse.data as any[]).map((activity) => new SelectOption(activity.name, activity.name));
        setResponse(options);
      }

      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];

  };


  static useSearchOfficesAPI = (): [SelectOption[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = 'offices/';
      const params = {
        search: query
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const options = (networkResponse.data as any[]).map((office: Office) => new SelectOption(office, office.name));
        setResponse(options);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

  static useSearchJudgesAPI = (): [SelectOption[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = `autocomplete/judges/`;
      const params = {
        search: query
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const options = (networkResponse.data as any[]).map((judges) => new SelectOption(judges.name, judges.name));
        setResponse(options);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

}

export default EventsService;
