import React, {Fragment, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';

import {IUserReducer, createPendingUser, goInSignUpReview, outSignUpReview} from '../store/reducers/UserReducer';
import Utility from '../utils/utils';
import StepFirst from './signup/StepFirst';
import StepSecond from './signup/StepSecond';
import StepThird from './signup/StepThird';
import StepFourth from './signup/StepFourth';
import StepReview from './signup/StepReview';
import UserService from '../services/UserServices';

import logo from '../image/logo-aiga.svg';
import signUp from '../image/registration__image.svg';
import welcome from '../image/welcome__image.svg';
import {FormFields} from '../values/values';
import {ISignUpError} from './signup/interface/SignupInterface';
import StepWelcome from './signup/StepWelcome';
import {IAppReducer} from '../store/reducers/AppReducer';
import StepFifth from './signup/StepFifth';
enum Step {
  first, second, third, fourth, fifth, review, welcome
}

const SignUp: React.FC = () => {
  const [step, setStep] = useState(Step.first);

  // Validation step field
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEMail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [unHidePassword, setUnHidePassword] = useState(false);
  const [isValidFiscalCode, setIsValidFiscalCode] = useState(true);
  const [isValidBirthDate, setIsValidBirthDate] = useState(true);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isValidCity, setIsValidCity] = useState(true);
  const [isValidAigaSection, setIsValidAigaSection] = useState(true);

  const initError: ISignUpError = {};
  const [serverError, setServerError] = useState(initError);

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const pendingSignUpUser = useSelector((state: IUserReducer) => state.user.pendingSignUpUser);
  const isInReview = useSelector((state: IUserReducer) => state.user.registerInReview);
  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);


  const [user, error, request] = UserService.useRegister();

  const dispatch = useDispatch();

  // Lifecycle
  useEffect(() => {
    dispatch(createPendingUser());
  }, []);

  useEffect(() => {
    if (error) {
      setServerError({...error});
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      Object.keys(error).forEach((key) => {
        switch (key) {
          case FormFields.first_name:
            setIsValidFirstName(false);
            break;
          case FormFields.last_name:
            setIsValidLastName(false);
            break;
          case FormFields.email:
            setIsValidEmail(false);
            break;
          case FormFields.password1:
            setIsValidPassword(false);
            break;
        }
      });
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      goToWelcome();
    }
  }, [user]);

  const validateStep = (t: Step): boolean => {
    if (!pendingSignUpUser) {
      return false;
    }

    switch (t) {
      case Step.first:
        return pendingSignUpUser!.validateName();
      case Step.second:
        return Utility.validateEmail(pendingSignUpUser!.email) &&
          Utility.validatePassword(pendingSignUpUser!.password) &&
          pendingSignUpUser!.validateFlag();
      case Step.third:
        return Utility.validateFiscalCode(pendingSignUpUser!.fiscal_code) && pendingSignUpUser!.checkDateValidity();
      case Step.fourth:
        return Utility.validatePhone(pendingSignUpUser!.phone);
      case Step.fifth:
        return !!pendingSignUpUser!.city && !!pendingSignUpUser!.aiga_section;
      case Step.review:
        return pendingSignUpUser!.checkSignUpValidity();
      default:
        return false;
    }
  };

  const goToStepFirst = () => {
    setStep(Step.first);
  };

  const goToStepSecond = () => {
    if (validateStep(Step.first)) {
      setStep(Step.second);
    }
  };

  const goToStepThird = () => {
    if (validateStep(Step.second)) {
      setStep(Step.third);
    }
  };

  const goToStepFour = () => {
    if (validateStep(Step.third)) {
      setStep(Step.fourth);
    }
  };

  const goToStepFive = () => {
    if (validateStep(Step.fourth)) {
      setStep(Step.fifth);
    }
  };

  const goToReview = () => {
    if (validateStep(Step.fifth)) {
      dispatch(goInSignUpReview());
      setStep(Step.review);
    }
  };

  const goToWelcome = () => {
    if (!!user) {
      setStep(Step.welcome);
      dispatch(outSignUpReview());
    }
  };

  const createAccount = () => {
    if (!isLoading && validateStep(Step.review)) {
      request(pendingSignUpUser!);
    }
  };

  const goToNextStep = () => {
    if (validateStep(step)) {
      switch (step) {
        case Step.first:
          goToStepSecond();
          break;
        case Step.second:
          goToStepThird();
          break;
        case Step.third:
          goToStepFour();
          break;
        case Step.fourth:
          goToStepFive();
          break;
        case Step.fifth:
          goToReview();
          break;
        case Step.review:
          createAccount();
          break;
      }
    }
  };

  const goToPreviousStep = () => {
    switch (step) {
      case Step.second:
        goToStepFirst();
        break;
      case Step.third:
        goToStepSecond();
        break;
      case Step.fourth:
        goToStepThird();
        break;
      case Step.fifth:
        goToStepFour();
        break;
    }
  };
  if (step === Step.first && currentUser) {
    return (<Redirect to={'/'} />);
  }

  return (
    <Fragment>
      <div className={classNames({'header-signup': step !== Step.review, 'header-review': step === Step.review})}>
        <img className={'logo'} src={logo} alt="Logo aiga" />
      </div>
      <div className={'wrapper-login'}>
        {isInReview && (
          <StepReview
            first_name={{valid: isValidFirstName, setValidity: setIsValidFirstName}}
            last_name={{valid: isValidLastName, setValidity: setIsValidLastName}}
            email={{valid: isValidEMail, setValidity: setIsValidEmail}}
            password={{valid: isValidPassword, setValidity: setIsValidPassword}}
            unHidePassword={{valid: unHidePassword, setValidity: setUnHidePassword}}
            fiscal_code={{valid: isValidFiscalCode, setValidity: setIsValidFiscalCode}}
            birth_date={{valid: isValidBirthDate, setValidity: setIsValidBirthDate}}
            phone={{valid: isValidNumber, setValidity: setIsValidNumber}}
            city={{valid: isValidCity, setValidity: setIsValidCity}}
            aiga_section={{valid: isValidAigaSection, setValidity: setIsValidFirstName}}
            serverError={{error: serverError, setError: setServerError}}
            next={goToNextStep}
          />
        )}
        {!isInReview && (
          <div className={'signup'}>
            <div className={'signup__image'}>
              <img src={step !== Step.welcome ? signUp : welcome} alt="splash" />
            </div>

            {pendingSignUpUser && (
              <div className={'signup__form'}>
                {/*STEP 1*/}
                {step === Step.first && (
                  <StepFirst
                    first_name={{valid: isValidFirstName, setValidity: setIsValidFirstName}}
                    last_name={{valid: isValidLastName, setValidity: setIsValidLastName}}
                    next={goToNextStep}
                  />
                )}

                {/*STEP 2*/}
                {step === Step.second && (
                  <StepSecond
                    email={{valid: isValidEMail, setValidity: setIsValidEmail}}
                    password={{valid: isValidPassword, setValidity: setIsValidPassword}}
                    unHidePassword={{valid: unHidePassword, setValidity: setUnHidePassword}}
                    next={goToNextStep}
                  />
                )}

                {/*STEP 3*/}
                {step === Step.third && <StepThird
                  fiscal_code={{valid: isValidFiscalCode, setValidity: setIsValidFiscalCode}}
                  birth_date={{valid: isValidBirthDate, setValidity: setIsValidBirthDate}}
                  next={goToNextStep}
                />}

                {/*STEP 4*/}
                {step === Step.fourth && <StepFourth
                  phone={{valid: isValidNumber, setValidity: setIsValidNumber}}
                  next={goToNextStep}
                />}

                {/*STEP 4*/}
                {step === Step.fifth && <StepFifth
                  city={{valid: isValidCity, setValidity: setIsValidCity}}
                  aiga_section={{valid: isValidAigaSection, setValidity: setIsValidAigaSection}}
                  next={goToNextStep}
                />}

                {step !== Step.welcome && (
                  <div className={'pagination'}>
                    <button tabIndex={-1} className={classNames('back', {unvisible: step === Step.first})}
                            onClick={goToPreviousStep} />

                    <div className={'pagination__pagination'}>
                  <span
                    className={classNames({active: step === Step.first})}
                    onClick={goToStepFirst}
                  />
                      <span
                        className={classNames({active: step === Step.second, disabled: !validateStep(Step.first)})}
                        onClick={goToStepSecond}
                      />
                      <span
                        className={classNames({active: step === Step.third, disabled: !validateStep(Step.second)})}
                        onClick={goToStepThird}
                      />
                      <span
                        className={classNames({active: step === Step.fourth, disabled: !validateStep(Step.third)})}
                        onClick={goToStepFour}
                      />
                      <span
                        className={classNames({active: step === Step.fifth, disabled: !validateStep(Step.fourth)})}
                        onClick={goToStepFive}
                      />
                    </div>
                    <button
                      className={classNames('button-primary', {disabled: !validateStep(step)})}
                      onClick={goToNextStep}
                    >
                      <span>Avanti</span>
                    </button>
                  </div>
                )}
              </div>
            )}

            {step === Step.welcome && (
              <div className={'signup__form'}>
                <StepWelcome />
              </div>
            )}

          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SignUp;
