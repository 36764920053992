import React from 'react';
import {Link} from 'react-router-dom';

const StepWelcome: React.FC = () => {
  return (
    <form>
      <h1>Fantastico</h1>
      <div className={'gdpr welcome'}>
        <p className={'copy'}>
          Il tuo account è stato creato correttamente. Ora puoi accedere ad
          AIGA: Agenda Legale
          ed utilizzare le sue innovative funzionalità.
        </p>
        <Link to={'signin'} style={{marginTop: 21, alignSelf: 'flex-start'}} className={'button-primary'}>
          <span>Accedi ad AIGA</span>
        </Link>
      </div>
    </form>
  );
};
export default StepWelcome;
