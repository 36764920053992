import React, {Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {setError500} from '../store/reducers/AppReducer';

const GlobalErrorModal: React.FC = () => {

  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className={'modal opened'}>
        <div className={'modal__content'}>
          <p className={'title'}>Errore</p>
          <p className={'copy'}>E' stato rilevato un errore</p>
          <button className={'button-primary'} onClick={() => {dispatch(setError500(false));}}>
            <span>Chiudi</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default GlobalErrorModal;
