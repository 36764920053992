import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import VisibilitySensor from 'react-visibility-sensor';
// tslint:disable-next-line
import 'moment/locale/it';
import CommitmentHeader from './CommitmentHeader';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import {
  IAgendaReducer,
  selectDayOnCalendar,
  setVisibilityOnDay,
  UpdateDaysType,
  setCurrentDay,
  setSearchPage,
  setEndReached
} from '../../store/reducers/AgendaReducer';
import { DateFormat } from '../../values/values';
import EventsService from '../../services/EventService';
import CommitmentEvent from './CommitmentEvent';
import strings from '../../values/strings';
import _ from 'lodash';
import { tabThirdClose } from '../../store/reducers/AppReducer';


const CommitmentWrapper: React.FC = () => {

  const dateEvents = useSelector((state: IAgendaReducer) => state.agenda.events);
  const searchedEvents = useSelector((state: IAgendaReducer) => state.agenda.searchedEvents);
  const dayOnTop = useSelector((state: IAgendaReducer) => state.agenda.onTopDay);
  const filters = useSelector((state: IAgendaReducer) => state.agenda.filters);
  const dayCurrent = useSelector((state: IAgendaReducer) => state.agenda.day);

  const searchPage = useSelector((state: IAgendaReducer) => state.agenda.searchPage);
  const isVisibleSearch = useSelector((state: IAgendaReducer) => state.agenda.isVisibleSearch);

  const dispatch = useDispatch();

  const [sentinelTop, sentinelTopInView] = useInView();
  const [sentinelDown, sentinelDownInView, entry] = useInView();

  const [, , request] = EventsService.useEventsAPI();


  const containment = useRef<HTMLDivElement>(null);
  const firstDay = useRef(null);

  useEffect(() => {
    if (dayCurrent) {
      const start = moment(dayCurrent).add(-1, 'month').add(-15, 'day').startOf('month').toDate();
      const end = moment(dayCurrent).add(1, 'month').add(15, 'day').endOf('month').toDate();
      request(start, end);
    }
  }, [dayCurrent]);

  // useEffect(() => {
  //   if (sentinelTopInView || sentinelDownInView) {
  //     //dispatch(selectDayOnCalendar(dayOnTop));
  //   }
  // }, [sentinelTopInView, sentinelDownInView]);

  useEffect(() => {
    if (dayCurrent) {
      const a = document.querySelectorAll('.a' + dayCurrent!.replace(/-/ig, ''))[0];
      if (a) {
        // @ts-ignore
        containment.current.scrollTop = a.offsetTop;
      }
    }
  }, [dateEvents]);

  useEffect(() => {
    if (dayCurrent) {
      const a = document.querySelectorAll('.a' + dayOnTop!.replace(/-/ig, ''))[0];
      if (a) {
        // @ts-ignore
        containment.current.scrollTop = a.offsetTop;
      }
    }
  }, [filters]);

  useEffect(() => {
    dispatch(selectDayOnCalendar(moment(new Date()).format(DateFormat.server)));
  }, []);

  useEffect(() => {
    dispatch(tabThirdClose());
  }, [])


  const hasFilters = !!filters && !!Object.keys(filters!).find((k) => filters![k]);

  let showLoadMore = () => {
    return Object.keys(dateEvents).length > 0
  }


  // INFINITE SCROLL

  let handleScroll = async (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    await dispatch(setEndReached(bottom))
  }

  return (
    <div className={'commitment'}>
      <CommitmentHeader />

      <div className={'commitment__date'} style={isVisibleSearch ? { position: 'absolute', top: -30000 } : { position: 'relative' }} ref={containment}>

        {showLoadMore() && <div className={'load-more'}>
          <button className={classNames('button-secondary')} type={'button'} onClick={() => {
            dispatch(setCurrentDay(Object.keys(dateEvents).sort()[0]))
          }}>
            <span >{strings.agenda.more}</span>
          </button>
        </div>}

        {dateEvents && Object.keys(dateEvents).sort().map((date, key) => {
          return (
            <>
              <VisibilitySensor partialVisibility={true} key={date}
                onChange={(isVisible) => dispatch(setVisibilityOnDay(date, isVisible))}
                containment={containment.current} >

                {({ isVisible }) => (
                  <>
                    {key === 1 && <div ref={sentinelTop} style={{ height: 0 }} />}
                    {key === (Object.keys(dateEvents).length - 1) && <div ref={sentinelDown} style={{ height: 0 }} />}
                    <div
                      className={classNames('commitment__date__content', 'a' + date.replace(/-/g, ''), { visible: isVisible })}
                      ref={key === 0 ? firstDay : null}>

                      <div className={classNames('commitment__date__content__date')}>
                        <p className={'day'}>{moment(date).format(DateFormat.dayShort)}</p>
                        <p className={'number'}>{moment(date).format(DateFormat.dayNumber)}</p>
                      </div>
                      {dateEvents[date].length > 0 && (
                        <div>
                          {dateEvents[date].filter((ev) => (!hasFilters || filters![ev.resourcetype!])).map((ev) =>
                            <CommitmentEvent event={ev} key={ev.id} />
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </VisibilitySensor>
            </>
          )
        })}

        {showLoadMore() && <div className={'load-more'}>
          <button className={classNames('button-secondary')} type={'button'} onClick={() => {
            dispatch(selectDayOnCalendar(dayOnTop!))
          }}>
            <span >{strings.agenda.more}</span>
          </button>
        </div>}

      </div>
      {true && <>
        {searchedEvents && (
          <div className={'commitment__date scale-in-tl search-result'} onScroll={handleScroll}>
            {searchedEvents.length > 0 && searchedEvents.map(ev =>
              <div key={ev.id} className={'commitment__date__content'} style={{ gridTemplateColumns: '0 100%', gridGap: '0 0' }}>
                <CommitmentEvent event={ev} showDate={true} />
              </div>
            )}
            {searchedEvents.length === 0 && (
              <div className={'none'}>Nessun elemento trovato</div>
            )}
          </div>
        )}
      </>}
    </div>
  );
};

export default CommitmentWrapper;
