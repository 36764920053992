export let AppName = 'AIGA';

export enum FormFields {
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  password = 'password',
  password1 = 'password1',
  password2 = 'password2',
  birth_date = 'birth_date',
  fiscal_code = 'fiscal_code',
  phone = 'phone',
  terms = 'terms',
  privacy = 'privacy',
  // gdpr = 'gdpr',

  birth_day = 'birth_day',
  birth_month = 'birth_month',
  birth_year = 'birth_year',

  profile = 'profile',
  city = 'city',
  section = 'aiga_section',

  //
  resourcetype = 'resourcetype',
  part1 = 'part1',
  part2 = 'part2',
  activity = 'activity',
  office = 'office',
  notice = 'notice_days',
  judge = 'judge',
  status = 'status',
  date = 'date',
  start = 'start',
  end = 'end',
  nrg = 'nrg',
  notes = 'notes',
  expense1 = 'expense1',
  expense2 = 'expense2',
  expense3 = 'expense3',
  allDay = 'all_day'
}


export enum ResourceType {
  Hearing = 'Hearing',
  Appointment = 'Appointment',
  Fulfillment = 'Fulfillment',
  Notice = 'Notice',
  News = 'News'
}

export enum EventStatus {
  TODO = 'TODO',
  DONE = 'DONE',
  POSTPONED = 'POSTPONED'
}

export enum NoticeType {
  _1 = 'Il giorno prima',
  _2 = 'Due giorni prima',
  _7 = 'Una settimana prima',
  _14 = 'Due settimane prima'
}

export enum PartsType {
  parts1 = 'parts1',
  parts2 = 'parts2'
}


export enum ExternalLinks {
  AIGAWebSite = 'http://www.aiga.it',
  Support = 'mailto:info@collegaonline.it?subject=Feedback AIGA: Agenda Legale&body=Caro team dell\' applicazione AIGA: Agenda Legale, ho utilizzato la vostra applicazione, e desidererei condividere il mio feedback: ',
  Terms = 'https://collegaonline.it/legal/aiga/',
  Privacy = 'https://www.iubenda.com/privacy-policy/80672923',
  // GDPR = 'https://google.com',
  CollegaWebsite = 'https://collegaonline.it'
}


export enum DateFormat {
  profile = 'DD/MsM/YYYY',
  server = 'YYYY-MM-DD',
  fullMonth = 'MMMM',
  month = 'MM',
  dayShort = 'ddd',
  dayWithNumber = 'dddd D',
  dayWithNumberAndMounth = 'dddd D MMMM',
  dayWithNumberAndMounthAndYear = 'dddd D MMMM YYYY',
  dayNumber = 'D',
  timeMicro = 'HH:mm:ss.SSSSSS',
  timeSecond = 'HH:mm:ss',
  time = 'HH:mm',
  full = 'DD MMMM YYYY',
  fullCalendar = 'YYYY-MM-DD HH:mm'
}
