import {User} from '../types/User';
import {IHash} from '../../utils/utils';
import {PendingUser} from '../types/PendingUser';

const SET_CURRENT_USER = 'SET_CURRENT_USER';
const CREATE_PENDING_USER = 'CREATE_PENDING_USER';
const UPDATE_PENDING_USER = 'UPDATE_PENDING_USER';
const GO_IN_SIGNUP_REVIEW = 'GO_IN_SIGNUP_REVIEW';
const OUT_IN_SIGNUP_REVIEW = 'OUT_IN_SIGNUP_REVIEW';
const SHOW_UNDER_46 = 'SHOW_UNDER_46';


export interface IUserReducer {
  user: IUserReducerState;
}

export interface IUserReducerState {
  currentUser?: User;
  pendingSignUpUser?: PendingUser;
  registerInReview: boolean;
  showUnder46: boolean;
}


// ACTIONS

export function setCurrentUser(user?: User) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_CURRENT_USER,
      user
    });
  };
}

export function createPendingUser() {
  return async (dispatch: any) => {
    await dispatch({
      type: CREATE_PENDING_USER,
      user: new PendingUser()
    });
  };
}

export function createPendingProfileUser(user: User) {
  return async (dispatch: any) => {
    await dispatch({
      type: CREATE_PENDING_USER,
      user: new PendingUser({...user})
    });
  };
}
export function emptyPendingUser() {
  return async (dispatch: any) => {
    await dispatch({
      type: CREATE_PENDING_USER,
      user: undefined
    });
  };
}

export function updatePendingUser(field: string, value?: any) {
  console.log(field + ' - ' + value);
  return async (dispatch: any) => {
    const updates: IHash = {
      [field]: value
    };
    updates[field] = value;

    await dispatch({
      type: UPDATE_PENDING_USER,
      updates
    });
  };
}

export function goInSignUpReview() {
  return {
    type: GO_IN_SIGNUP_REVIEW
  };
}


export function outSignUpReview() {
  return {
    type: OUT_IN_SIGNUP_REVIEW
  };
}

export function setShowUnder46(isVisible: boolean) {
  return {
    type: SHOW_UNDER_46,
    visible: isVisible
  };
}

const INITIAL_STATE = {registerInReview: false, showUnder46: false};

// REDUCER
export default function (state: IUserReducerState = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SHOW_UNDER_46:
      return {
        ...state,
        showUnder46: action.visible
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user ? new User(action.user) : undefined
      };

    case CREATE_PENDING_USER:
      return {
        ...state,
        pendingSignUpUser: action.user
      };

    case UPDATE_PENDING_USER:
      return {
        ...state,
        pendingSignUpUser: new PendingUser({
          ...state.pendingSignUpUser,
          ...action.updates
        })
      };
    case GO_IN_SIGNUP_REVIEW:
      return {
        ...state,
        registerInReview: true
      };
    case OUT_IN_SIGNUP_REVIEW:
      return {
        ...state,
        pendingSignUpUser: undefined,
        registerInReview: false
      };
    default:
      return state;
  }
}
