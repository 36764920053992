import React from 'react';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import WebFont from 'webfontloader';
import {registerLocale} from 'react-datepicker';
// tslint:disable-next-line:no-implicit-dependencies no-submodule-imports
import it from 'date-fns/locale/it';

import reducers from './store/reducers';

import AppRouter from './navigation/Router';

registerLocale('it', it);

WebFont.load({
  google: {
    families: ['Roboto', 'sans-serif']
  },
  custom: {
    families: ['SF UI Display', 'helvetica'],
    urls: ['./src/fonts/SFUI/font.css', './src/fonts/helvetica/font.css']
  }
});

const App: React.FC = () => {

  // const composeEnhancers = (__DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
  //   compose;

  const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};


export default App;
