import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Storage from '../store/Storage';
import {setCurrentUser} from '../store/reducers/UserReducer';
import logo from '../image/logo-aiga.svg';

const Logout: React.FC = () => {

  const dispatch = useDispatch();

  const logout = async () => {
    await Storage.set(Storage.AUTH_TOKEN, null);
    dispatch(setCurrentUser(undefined));
  };

  useEffect(() => {
    logout();
  }, []);
  return (
    <div className={'loading-wrapper'}>
      <div className={'logo-container'}>
        <img className={'logo'} src={logo} alt="Logo aiga" />
      </div>
      <h1>Loading</h1>
      <div className={'loader'} />
    </div>
  );
};

export default Logout;
