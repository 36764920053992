import React from 'react';
import logo from '../image/logo-aiga.svg';

const OnboardingMob: React.FC = () => {

  return (
    <div className={'onboarding-cntr-mob'}>
      <div className={'header-onboarding-mob'}>
        <img className={'logo'} src={logo} alt="Logo aiga" />
        <p>L'applicazione pensata per i giovani avvocati. L'utilizzo dell'applicazione è limitato ai soli
          iscritti all'associazione AIGA.</p>
      </div>
      <div className={'wrapper-on-mob'}>
        <div className={'onboarding-mob'}>
          <div className={'onboarding__content'}>
            <h2>Tutto a portata di mano:</h2>
            <h1>Appuntamenti, Adempimenti, Avvisi e Udienze.</h1>
            <p>AIGA ha pensato per te un calendario intelligente che ti consente di programmare con facilità
              e senza stress i tuoi impegni.</p>
          </div>
          <div className={'onboarding__content'}>
            <h2>Creazione del verbale d'udienza?</h2>
            <h1>Sarai veloce come un razzo.</h1>
            <p>Crea il verbale ovunque ti trovi, invita i tuoi colleghi a lavorare sullo stesso documento da
              qualsiasi dispositivo: telefono, tablet o computer.</p>
          </div>
          <div className={'onboarding__content'}>
            <div className={'title'}>
              <h1 style={{ marginBottom: 40, paddingBottom: 0 }}>Cosa aspetti?</h1>
              <h2>Installala ora!</h2>
            </div>
            <div className={'cta-container'}>
              {/* TODO: CHECK URL */}
              <a className={'app-store'} href="https://apps.apple.com/app/id1486152673" target="_blank" />
              {/* TODO: CHECK URL */}
              <a className={'google-play'} href="https://play.google.com/store/apps/details?id=it.collega.aiga" target="_blank" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingMob;
