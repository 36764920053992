import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IUserReducer, updatePendingUser} from '../../store/reducers/UserReducer';
import strings from '../../values/strings';
import InputIconValidate from '../../components/common/InputIconValidate';
import Input from '../../components/common/Input';
import {FormFields} from '../../values/values';
import Utility from '../../utils/utils';
import classNames from 'classnames';
import {IStepReview} from './interface/SignupInterface';
import {IAppReducer} from '../../store/reducers/AppReducer';
import UserService from '../../services/UserServices';
import SelectOption from '../../store/types/SelectionOption';
import InputAutosuggest from '../../components/common/AutoSuggest';

const StepReview: React.FC<IStepReview> = (props) => {
  const {first_name, last_name, email, password, unHidePassword, fiscal_code, birth_date, phone, city, aiga_section, serverError, next} = props;

  const pendingSignUpUser = useSelector((state: IUserReducer) => state.user.pendingSignUpUser);
  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);
  const dispatch = useDispatch();
  const [suggCity, , requestCity] = UserService.useSearcCitiesAPI();
  const [suggSection, , requestSection] = UserService.useSearchSectionsAPI();
  const [cityInput, setCityInput] = useState(pendingSignUpUser!.profile!.city ? pendingSignUpUser!.profile!.city!.name! : '');
  const [sectionInput, setSectionInput] = useState(pendingSignUpUser!.profile!.aiga_section! ? pendingSignUpUser!.profile!.aiga_section!.name! : '');
  const [serverValidation, setServerValidation] = useState(false);

  const unHide = () => {
    unHidePassword.setValidity(!unHidePassword.valid);
  };

  useEffect(() => {

    if (pendingSignUpUser) {
      if (pendingSignUpUser.checkDateField()) {
        const isValid = pendingSignUpUser.checkDateValidity();
        birth_date.setValidity(isValid);
        if (isValid) {
          const {birth_date: _, ...rest} = serverError.error;
          serverError.setError(rest);
        }
      } else {
        birth_date.setValidity(false);
      }
    }
  }, [pendingSignUpUser]);

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  useEffect(() => {
    if (Object.keys(serverError.error).length > 0) {
      setServerValidation(true);
    }
  }, [serverError.error]);


  if (!pendingSignUpUser) {
    return null;
  }

  return (
    <form onSubmit={preventSubmit(next)} className={'review'}>
      <div className={'review__form'}>
        <h1>{strings.signUp.summary.headerTitle}</h1>
        <p>{strings.signUp.summary.headerDescription}</p>

        <div className={'review__form__row'}>
          {/*Name*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>Nome</label>
              {serverValidation && <InputIconValidate isValid={first_name.valid} />}
            </div>
            <Input
              type="text"
              placeholder={strings.fieldPlaceholderFirstName}
              value={pendingSignUpUser!.first_name}
              readOnly={!serverValidation || isLoading}
              disabled={!serverValidation || isLoading}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updatePendingUser(FormFields.first_name, event.target.value));
                    if (event.target.value.length > 0) {
                      first_name.setValidity(true);
                      const {first_name: _, ...rest} = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    first_name.setValidity(pendingSignUpUser!.first_name.length > 0);
                  }
                }
              }
              error={!first_name.valid ? (serverError.error.first_name ? serverError.error.first_name : strings.fieldPlaceholderFirstName) : undefined}
            />
          </div>

          {/*Surname*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>Cognome</label>
              {serverValidation && <InputIconValidate isValid={last_name.valid} />}
            </div>
            <Input
              type="text"
              readOnly={!serverValidation || isLoading}
              disabled={!serverValidation || isLoading}
              placeholder={strings.fieldPlaceholderLastName}
              value={pendingSignUpUser!.last_name}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updatePendingUser(FormFields.last_name, event.target.value));
                    if (pendingSignUpUser!.last_name.length > 0) {
                      last_name.setValidity(true);
                      const {last_name: _, ...rest} = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    last_name.setValidity(pendingSignUpUser!.last_name.length > 0);
                  }
                }
              }
              error={!last_name.valid ? (serverError.error.last_name ? serverError.error.last_name : strings.fieldPlaceholderLastName) : undefined}
            />
          </div>
        </div>


        <div className={'review__form__row'}>
          {/*Email*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>E-mail</label>
              {serverValidation && <InputIconValidate isValid={email.valid} />}
            </div>
            <Input
              type="text"
              readOnly={!serverValidation || isLoading}
              disabled={!serverValidation || isLoading}
              placeholder={strings.fieldPlaceholderEmail}
              value={pendingSignUpUser!.email}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updatePendingUser(FormFields.email, event.target.value));
                    if (Utility.validateEmail(event.target.value)) {
                      email.setValidity(true);
                      const {email: _, ...rest} = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    email.setValidity(Utility.validateEmail(event.target.value));
                  }
                }
              }
              error={!email.valid ? (serverError.error.email ? serverError.error.email : strings.errors.invalidEmail) : undefined}
            />
          </div>

          {/*Password*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>Password</label>
              {serverValidation && <InputIconValidate isValid={password.valid} />}
            </div>
            <div className={'password-container'}>
              <span className={classNames('unhide', {hide: unHidePassword.valid})} onClick={unHide} />
              <Input
                readOnly={!serverValidation || isLoading}
                type={!unHidePassword.valid ? 'password' : 'text'}
                disabled={!serverValidation || isLoading}
                placeholder={strings.fieldPlaceholderPassword}
                value={pendingSignUpUser!.password}
                onChange={
                  (event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      dispatch(updatePendingUser(FormFields.password, event.target.value));
                      if (Utility.validatePassword(event.target.value)) {
                        password.setValidity(true);
                        const {password1: _, ...rest} = serverError.error;
                        serverError.setError(rest);
                      }
                    }
                  }
                }
                onBlur={
                  (event?: React.FocusEvent<HTMLInputElement>) => {
                    if (event) {
                      password.setValidity(Utility.validatePassword(event.target.value));
                    }
                  }
                }
                error={!password.valid ? (serverError.error.password1 ? serverError.error.password1 : strings.errors.invalidPassword) : undefined}
              />
            </div>
          </div>
        </div>

        <div className={'review__form__row'}>
          {/*BirthDate*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>Data di nascita</label>
              {serverValidation && <InputIconValidate isValid={birth_date.valid} />}
            </div>
            <div className={'date-container'}>
              <input type="text" placeholder={'GG'}
                     readOnly={!serverValidation || isLoading}
                     disabled={!serverValidation || isLoading}
                     value={pendingSignUpUser!.birth_day}
                     onChange={
                       (event?: React.ChangeEvent<HTMLInputElement>) => {
                         if (event) {
                           if (!event.target.value) {
                             dispatch(updatePendingUser(FormFields.birth_day, ''));
                           } else if (!isNaN(Number(event.target.value))) {
                             const value = +event.target.value;
                             if (value < 1) {
                               dispatch(updatePendingUser(FormFields.birth_day, '1'));
                             } else if (value > 31) {
                               dispatch(updatePendingUser(FormFields.birth_day, '31'));
                             } else {
                               dispatch(updatePendingUser(FormFields.birth_day, event.target.value));
                             }
                           }
                         }
                       }
                     }
                     onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                       event.target.select();
                     }}
              />
              <div className={classNames('select-container', {'read-only': (!serverValidation || isLoading)})}>
                <span />
                <select
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    dispatch(updatePendingUser(FormFields.birth_month, event.target.value));
                  }}
                  value={pendingSignUpUser!.birth_month}
                  disabled={!serverValidation || isLoading}
                >
                  <option disabled={true} value={'-1'}> -- mese --</option>
                  <option value={'0'}>Gennaio</option>
                  <option value={'1'}>Febbraio</option>
                  <option value={'2'}>Marzo</option>
                  <option value={'3'}>Aprile</option>
                  <option value={'4'}>Maggio</option>
                  <option value={'5'}>Giugno</option>
                  <option value={'6'}>Luglio</option>
                  <option value={'7'}>Agosto</option>
                  <option value={'8'}>Settembre</option>
                  <option value={'9'}>Ottobre</option>
                  <option value={'10'}>Novembre</option>
                  <option value={'11'}>Dicembre</option>
                </select>
              </div>
              <input type="text" placeholder={'AAAA'}
                     value={pendingSignUpUser!.birth_year}
                     readOnly={!serverValidation || isLoading}
                     disabled={!serverValidation || isLoading}
                     onChange={
                       (event?: React.ChangeEvent<HTMLInputElement>) => {
                         if (event) {
                           if (!event.target.value) {
                             dispatch(updatePendingUser(FormFields.birth_year, ''));
                           } else if (!isNaN(Number(event.target.value))) {
                             dispatch(updatePendingUser(FormFields.birth_year, event.target.value));
                           }
                         }
                       }
                     }
                     onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                       event.target.select();
                     }}
              />
            </div>
            {/*{!birth_date.valid && <p className={'error'}>{serverError.error.birth_date ? serverError.error.birth_date : strings.errors.invalidDate}</p>}*/}
            {!birth_date.valid && <p className={'error'}>{strings.errors.invalidDate}</p>}
          </div>

          {/*Fiscal Code*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>Codice Fiscale</label>
              {serverValidation && <InputIconValidate isValid={fiscal_code.valid} />}
            </div>
            <Input
              name={'fiscal-code'}
              readOnly={!serverValidation || isLoading}
              disabled={!serverValidation || isLoading}
              placeholder={strings.fieldPlaceholderFiscalCode}
              value={pendingSignUpUser!.fiscal_code}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updatePendingUser(FormFields.fiscal_code, event.target.value.toUpperCase()));
                    if (Utility.validateFiscalCode(event.target.value)) {
                      fiscal_code.setValidity(true);
                      const {fiscal_code: _, ...rest} = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    fiscal_code.setValidity(Utility.validateFiscalCode(event.target.value));
                  }
                }
              }
              error={!fiscal_code.valid ? (serverError.error.fiscal_code ? serverError.error.fiscal_code : strings.errors.invalidFiscalCode) : undefined}
            />
          </div>
        </div>

        <div className={'review__form__row'}>
          {/*Phone*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>Numero di telefono</label>
              {serverValidation && <InputIconValidate isValid={phone.valid} />}
            </div>
            <Input
              readOnly={!serverValidation || isLoading}
              disabled={!serverValidation || isLoading}
              placeholder={strings.fieldPlaceholderPhone}
              value={pendingSignUpUser!.phone}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updatePendingUser(FormFields.phone, event.target.value));
                    if (Utility.validatePhone(event.target.value)) {
                      phone.setValidity(true);
                      const {phone: _, ...rest} = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    phone.setValidity(Utility.validatePhone(event.target.value));
                  }
                }
              }
              error={!phone.valid ? (serverError.error.phone ? serverError.error.phone : strings.errors.invalidPhone) : undefined}
            />
          </div>
        </div>

        <div className={'review__form__row'}>
          {/*City*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>{strings.signUp.fifth.cityLabel}</label>
              {serverValidation && <InputIconValidate isValid={city.valid} />}
            </div>
            {(!serverValidation || isLoading) && (
              <Input
                readOnly={!serverValidation || isLoading}
                disabled={!serverValidation || isLoading}
                placeholder={strings.signUp.fifth.cityPlaceholder}
                value={cityInput}
                error={!city.valid ? ((serverError.error.profile && serverError.error.profile.city) ? serverError.error.profile.city : strings.errors.invalidField) : undefined}
              />
            )}
            {!(!serverValidation || isLoading) && (
              <InputAutosuggest
                readOnly={!serverValidation || isLoading}
                placeholder={strings.signUp.fifth.cityPlaceholder}
                suggestions={suggCity || []}
                onChange={(value) => {
                  setCityInput(value);
                  if (!value) {
                    dispatch(updatePendingUser(FormFields.profile, {
                      ...pendingSignUpUser.profile,
                      city: null
                    }));
                  }
                }}
                value={cityInput}
                onFetch={requestCity}
                onSelect={(sugg: SelectOption) => {
                  dispatch(updatePendingUser(FormFields.city, sugg.value));
                  const {profile, ...rest} = serverError.error;
                  if (sugg) {
                    if (profile) {
                      const {city: c, aiga_section: a} = profile;
                      if (a) {
                        serverError.setError({...rest, profile: {aiga_section: a}});
                      } else {
                        serverError.setError({...rest});
                      }
                    }
                    city.setValidity(true);
                  }
                }}
                onBlur={(s) => {
                  if (!pendingSignUpUser!.profile || (pendingSignUpUser!.profile && !pendingSignUpUser!.profile.city)) {
                    setCityInput('');
                    city.setValidity(false);
                  } else {
                    if (!s) {
                      setCityInput(pendingSignUpUser!.profile!.city!.name || '');
                    }
                  }
                }}
                error={!city.valid ? ((serverError.error.profile && serverError.error.profile.city) ? serverError.error.profile.city : strings.errors.invalidField) : undefined}
              />
            )}
          </div>

          {/*Aiga Section*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>{strings.signUp.fifth.sectionLabel}</label>
              {serverValidation && <InputIconValidate isValid={aiga_section.valid} />}
            </div>
            {(!serverValidation || isLoading) && (
              <Input
                readOnly={!serverValidation || isLoading}
                disabled={!serverValidation || isLoading}
                placeholder={strings.signUp.fifth.sectionPlaceholder}
                value={sectionInput}
                error={!aiga_section.valid ? ((serverError.error.profile && serverError.error.profile.aiga_section) ? serverError.error.profile.aiga_section : strings.errors.invalidField) : undefined}
              />
            )}
            {!(!serverValidation || isLoading) && (
              <InputAutosuggest
                readOnly={!serverValidation || isLoading}
                placeholder={strings.signUp.fifth.sectionPlaceholder}
                suggestions={suggSection || []}
                onChange={(value) => {
                  setSectionInput(value);
                  if (!value) {
                    dispatch(updatePendingUser(FormFields.profile, {
                      ...pendingSignUpUser.profile,
                      aiga_section: null
                    }));
                  }
                }}
                value={sectionInput}
                onFetch={requestSection}
                onSelect={(sugg: SelectOption) => {
                  dispatch(updatePendingUser(FormFields.section, sugg.value));
                  const {profile, ...rest} = serverError.error;
                  if (sugg) {
                    if (profile) {
                      const {city: c, aiga_section: a} = profile;
                      if (c) {
                        serverError.setError({...rest, profile: {city: c}});
                      } else {
                        serverError.setError({...rest});
                      }
                    }
                    aiga_section.setValidity(true);
                  }
                }}
                onBlur={(s) => {
                  if (!pendingSignUpUser!.profile || (pendingSignUpUser!.profile && !pendingSignUpUser!.profile.aiga_section)) {
                    setSectionInput('');
                    aiga_section.setValidity(false);
                  } else {
                    if (!s) {
                      setSectionInput(pendingSignUpUser!.profile!.aiga_section!.name || '');
                    }
                  }
                }}
                error={!aiga_section.valid ? ((serverError.error.profile && serverError.error.profile.aiga_section) ? serverError.error.profile.aiga_section : strings.errors.invalidField) : undefined}
              />
            )}
          </div>
        </div>
      </div>

      <div className={'review__cta'}>
        <button
          className={classNames('button-primary', {disabled: !(pendingSignUpUser!.checkSignUpValidity()) || Object.keys(serverError.error).length > 0})}>
          <span className={classNames({unvisible: isLoading})}>Crea Account</span>
          {isLoading && <span className={'button-loading'} />}
        </button>
      </div>
    </form>
  );
};

export default StepReview;
