import { Notification } from "../types/Notification";
var _ = require('lodash');

const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
const SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS'
const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'


export interface NotificationsReducer {
    notificationsReducer: NotificationsReducerState
}

export interface NotificationsReducerState {
    notifications: Array<Notification>
    unreadNotifications: number
}


// ACTIONS

export function setNotifications(notifications: Array<Notification>) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_NOTIFICATIONS,
            notifications: notifications
        })
    }
}

export function setUnreadNotifications(unreadNotifications: number) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_UNREAD_NOTIFICATIONS,
            unreadNotifications: unreadNotifications
        })
    }
}

export function resetNotificationsStore() {
    return async (dispatch: any) => {
        await dispatch({
            type: RESET_NOTIFICATIONS
        })
    }
}


// REDUCER
const INITIAL_STATE = { notifications: [], unreadNotifications: 0 }

export function notificationReducer(state: NotificationsReducerState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case SET_NOTIFICATIONS:

            let _array = state.notifications.concat(
                action.notifications.filter((notification: Notification) => {
                    return state.notifications.indexOf(notification) === -1
                })
            )

            return {
                ...state,
                notifications: _array
            }

        case SET_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                unreadNotifications: action.unreadNotifications
            }

        case RESET_NOTIFICATIONS:
            return INITIAL_STATE

        default: return state
    }
}